
import React, { useState, useEffect } from "react";
import { RiCloseFill } from 'react-icons/ri'
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { newBusiness, updateBusiness } from "../../constants/routes.path";
import authService from "../../app/service/auth.service";
import { addBusiness, updateBusinessSlice } from "../../app/slice/business.slice";



const OrganizationModal = ({ onClose, isVisible, isCreate, data }) => {

    const payamentcat = useSelector((state) => state.business.categories);

    const dispatch = useDispatch();

    const [organization, setOrganization] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [building, setBuilding] = useState("new");
    const [paymentclass, setPaymentclass] = useState("select");

    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        function init() {
            setOrganization(!data ? "" : data.organization);
            setAddress(!data ? "" : data.address);
            setPhone(!data ? "" : data.residential);
            setEmail(!data ? "" : data.email);
            // setPaymentclass(!data ? "" : payamentcat[data.Category.id].facility)
            // setBuilding(!data ? "" : data.building);
        }
        init();
    }, [data, payamentcat]);

    const toggleLoading = () => {
        setLoading((prevLoading) => !prevLoading);
    };

    const createOrganization = async (e) => {
        e.preventDefault();

        console.log("xyz", paymentclass);

        if (!organization || !address || !phone || !building) {
            toast.error('Please complete all fields.');
            return;
        }


        toggleLoading();

        try {

            const newData = {
                organization,
                address,
                residential: phone,
                email,
                paymentclass,
                building,
                phone,
            }

            if (!data) {

                if(paymentclass === "select" ){
                    toast.error('Please select business category');
                    return;
                }

                const response = await axios.post(newBusiness, newData, { headers: authService.authHeader() });
                const message = response.data.message;
                const data = response.data.data;
                dispatch(addBusiness(data))
                onClose()
                toast.success(message);

            } else {

                const url = updateBusiness+"/"+data.id;
                const response = await axios.put(url, newData, { headers: authService.authHeader() });
                const message = response.data.message;
                const responsedata = response.data.data;
                dispatch(updateBusinessSlice(responsedata))
                onClose()
                toast.success(message);
            }

        } catch (error) {
            console.log(error)
            toast.error(error.response.statusText);
        } finally {
            toggleLoading();
        }
    }

    return (

        <div className={isVisible ? 'hidden' : 'fixed z-50 w-[100%] md:w-[94%] h-[85%] bg-background bg-opacity-80  flex justify-center items-center'}>
            <ToastContainer />
            <form method="post" onSubmit={createOrganization} className={'w-[90%] mr-10 md:mr-0 md:w-[40%]  rounded-md md:left-[25%]  bg-white absolute'}>
                <div className=' flex items-center justify-between px-5 py-3'>
                    <p className=' text-hint font-semibold'>New Business</p>
                    <button onClick={onClose} type="button"><RiCloseFill className=' text-red-500' /></button>
                </div>
                <hr className=' my-2' />


                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 0 * 2).animate}
                    className="mt-2 mx-5"
                >
                    <p className="text-hint">Name of Organization</p>
                    <input
                        value={organization}
                        onChange={(e) => {
                            setOrganization(e.target.value);
                        }}
                        className="w-full bg-background mt-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"
                        placeholder="Enter name of organization"
                        type="businessname"
                        name="businessname"
                        autoComplete="businessname"
                    />

                </motion.div>


                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 0 * 2).animate}
                    className="mt-2 mx-5"
                >
                    <p className="text-hint">Address *</p>

                    <input
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value);
                        }}
                        className="w-full bg-background mt-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"
                        placeholder="Enter address of organization"
                        type="address"
                        name="address"
                        autoComplete="address"
                    />

                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 0 * 2).animate}
                    className="mt-2 mx-5"
                >
                    <p className="text-hint">Email</p>

                    <input
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        className="w-full bg-background mt-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"
                        placeholder="Enter email of organization"
                        type="email"
                        name="email"
                        autoComplete="email"
                    />

                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 0 * 2).animate}
                    className="mt-2 mx-5"
                >
                    <p className="text-hint">Phone Number *</p>

                    <input
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                        className="w-full bg-background mt-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"
                        placeholder="Enter Phone Number"
                        type="phone"
                        name="phone"
                        autoComplete="phone"
                    />

                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 0 * 2).animate}
                    className={data ? "hidden" : "mt-2 mx-5"}
                >
                    <p className="text-hint">Category *</p>

                    <select
                        onChange={(e) => {
                            console.log(e.target.value);
                            setPaymentclass(e.target.value);
                        }}
                        className="w-full bg-background mt-2 focus:outline-none rounded-sm py-2 px-2  font-light"
                        name="paymentclass"
                        defaultValue={paymentclass}
                    >
                        <option value={paymentclass} disabled >--- Organization's  Category --</option>
                        {payamentcat.map((element, index) => (
                            <option value={element.id} key={index} >{element.facility}</option>

                        ))}

                    </select>

                </motion.div>



                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 0 * 2).animate}
                    className={data ? "hidden" : "mt-2 mx-5"}

                >
                    <p className="text-hint">Building *</p>

                    <select
                        onChange={(e) => {
                            console.log(e.target.value);
                            setBuilding(e.target.value);
                        }}
                        className="w-full bg-background mt-2 focus:outline-none rounded-sm py-2 px-2  font-light"
                        name="email"
                        defaultValue={building}
                    >
                        <option value={"old"} >Old Building</option>
                        <option value={"new"} >New Building</option>


                    </select>

                </motion.div>



                <div className='flex justify-between mx-5 mt-5 mb-3'>
                    <div className=''>

                    </div>
                    <button className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient px-5 py-2 font-light rounded-md">

                        {isLoading ? "Processing..." : isCreate ? "Create" : "Update"}
                    </button>
                </div>

            </form>

        </div>


    )
}

export default OrganizationModal