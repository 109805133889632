import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  demands: [],
};

const demandSlice = createSlice({
  name: 'demand',
  initialState,
  reducers: {
    loadDemand(state, action){
      state.demands = action.payload;
    },
    addDemand(state, action) {
      state.demands.unshift(action.payload);

    },
    removeDemand(state, action) {
      state.demands = state.demands.filter(demand => demand.id !== action.payload.id);
    },
    updateDemandSlice(state, action) {
      const newData = action.payload;
      const index = state.demands.findIndex(user => user.id === newData.id);
      if (index !== -1) {
        state.demands[index] = { ...state.demands[index], ...newData };
      }
    },
  },
});

export const {loadDemand, addDemand, removeDemand, updateDemandSlice } = demandSlice.actions;
export default demandSlice.reducer;