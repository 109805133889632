import React, { useState } from "react";
import { appImages } from "../../data";
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import { BiEnvelope } from "react-icons/bi";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyemail } from "../../constants/routes.path";
import { Link } from "react-router-dom";

function EmailVerification() {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);

  const toggleLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!email) {
      toast.error('Please fill in all fields.');
      return;
    }


    toggleLoading();
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    const userData = {email};

    try {
      const response = await axios.post(verifyemail, userData);
      const message = response.data.message;
      toast.success(message);
    } catch (error) {

      const errorMessage = error.response.data.message
      toast.error(errorMessage);

    } finally {
      toggleLoading();
    }
  };



  return (
   <form method="post" onSubmit={handleSubmitEvent}>
     <div className=" flex flex-row">
      <div className=" bg-gradient overflow-y-scroll overflow-x-hidden h-screen justify-between w-[45%] px-10 pt-5 hidden md:flex flex-col">
        <motion.h3
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-white"
        >
          <b>BRIDGE</b>NUMERIC
        </motion.h3>
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 0 * 2).animate}
        >
          <img src={appImages.avater} alt="" className=" w-2/3 mt-1" />
        </motion.div>

        <motion.h1
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 1 * 2).animate}
          className=" text-5xl font-medium text-white"
        >
          Let's setup <br /> your Operating <br /> Agreement
        </motion.h1>

        <motion.div
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 3 * 2).animate}
          className=" mt-10 w-[90%]"
        >
          <p className="text-white mb-10">
            Remote solution for seamless organizational data collection,
            validation and management across the nation.
          </p>
        </motion.div>
      </div>

      {/* Right Hand Side */}
      <div className={`mx-5 md:mx-20 } md:w-[40%] w-[100%]`}>
        <img src={appImages.logo} alt="" className="  w-44 mt-20" />

        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 1 * 2).animate}
        >
          <h1 className="text-3xl font-semibold mt-10">
            Email Verification 👋
          </h1>
          <p className="text-hint mt-1">
            Enter the email address used during your account
            registration
          </p>
        </motion.div>
        <div>
          <ToastContainer />

          <motion.div
            initial={slideIn("right", null).initial}
            whileInView={slideIn("right", 1 * 2).animate}
            className=" mt-24"
          >
            <p className="text-hint">Email</p>
            <div className="flex mt-2 py-3 px-3 rounded-sm  justify-center items-center bg-background">
              <BiEnvelope className="text-2xl text-hint" />
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full bg-transparent  focus:outline-none rounded-md px-2  font-light"
                placeholder="Enter email here"
                type="email"
                name="email"
                autoComplete="email"
              />
            </div>
          </motion.div>

          <motion.button
            initial={slideIn("down", null).initial}
            whileInView={slideIn("down", 0 * 2).animate}
            className="bg-gradient mt-14 w-[100%] py-3 rounded-sm text-white"
            type="submit"
          >
            {isLoading ? "Please wait..." : "Verify Email"}
          </motion.button>
        </div>
        <div className=" w-[100%]">
        <p className=" text-center mt-5 text-hint">
            Return back to{" "}
            <Link to={"/authentication"} className="text-primary font-bold">Login</Link>
          </p>
        </div>
      </div>
    </div>
   </form>
  );
}

export default EmailVerification;
