import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const SimplePieChart = () => {
  const data = [
    { title: "Label 1", value: 30, color: "#FB7339" },
    { title: "Label 2", value: 70, color: "#23C55E" },
  ];

  const chartOptions = {
  paddingAngle: 0,
  startAngle: -100,
  totalValue: 100,
  shift: 5,
};

return <PieChart data={data} radius={40} segmentsShift={2} viewBoxSize={[100, 100]} {...chartOptions} />;

  // return <PieChart data={data} />;
};

export default SimplePieChart;
