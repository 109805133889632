export function calculatePercentageChange(paymentInstance) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Calculate last month's date
  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  // Filter payments for this month and last month
  const paymentsThisMonth = paymentInstance.filter(payment => {
    const createdAtDate = new Date(payment.createdAt);
    return createdAtDate.getMonth() === currentMonth && createdAtDate.getFullYear() === currentYear;
  });

  const paymentsLastMonth = paymentInstance.filter(payment => {
    const createdAtDate = new Date(payment.createdAt);
    return createdAtDate.getMonth() === lastMonth && createdAtDate.getFullYear() === lastMonthYear;
  });

  // Sum up the amounts for this month and last month
  const totalAmountThisMonth = paymentsThisMonth.reduce((total, payment) => total + payment.amount, 0);
  const totalAmountLastMonth = paymentsLastMonth.reduce((total, payment) => total + payment.amount, 0);

  // Calculate the percentage change
  let percentageChange = ((totalAmountThisMonth - totalAmountLastMonth ) *100)/totalAmountThisMonth >= 1 ? "+" + ((totalAmountThisMonth - totalAmountLastMonth ) *100)/totalAmountThisMonth : ((totalAmountThisMonth - totalAmountLastMonth ) *100)/totalAmountThisMonth;


  return  percentageChange

}
