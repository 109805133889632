import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/auth.slice';
import accountReducer from './slice/account.slice';
import businessReducer from './slice/business.slice';
import demandReducer from './slice/demand.slice';
import paymentReducer from './slice/payment.slice';
import appSlice from './slice/app.slice';



export default configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    business: businessReducer,
    demand: demandReducer,
    payment: paymentReducer,
    app: appSlice
  },
});