import { useState } from "react";
import { BiLock, BiEnvelope, BiPhone } from "react-icons/bi";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { MdOutlinePerson } from "react-icons/md";
import { motion } from "framer-motion";
import { slideIn } from "../../../motion";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
import { register } from '../../../constants/routes.path';


function SignupSection() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // let navigate = useNavigate()

  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    if (!email || !firstname || !lastname || !password || !phone) {
      toast.error('Please fill in all fields.');
      return;
    }

    toggleLoading();
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    try {

      const userData = {
        email,
        firstname,
        lastname,
        password,
        phone,
      }

      const response = await axios.post(register, userData);
      // const data = response.data.data;
      // const token = response.data.token;
      const message = response.data.message;
      window.location.reload();
      // navigate('/otpverification')
      toast.success(message);

    } catch (error) {
      const errorMessage = error.response.data.message
      toast.error(errorMessage);
    } finally {
      toggleLoading();
    }
  };

  const toggleVisibilty = () => {
    setIsVisible(!isVisible);
  };

  const toggleLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  return (
    <form method="POST" onSubmit={handleSubmitEvent}>
      <ToastContainer />
      <div className="flex gap-x-3">
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 0 * 2).animate}
          className=" w-1/2 mt-5"
        >
          <p className="text-hint">Firstname</p>
          <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
            <MdOutlinePerson className="text-2xl text-hint" />
            <input
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
              className="w-full bg-transparent  focus:outline-none rounded-md py-3 px-2 intrude font-light"
              placeholder="Enter firstname"
              type="firstname"
              name="firstname"
              autoComplete="given-name"
            />
          </div>
        </motion.div>

        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 2 * 2).animate}
          className=" w-1/2 mt-5"
        >
          <p className="text-hint">Lastname</p>
          <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
            <MdOutlinePerson className="text-2xl text-hint" />
            <input
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
              className="w-full bg-transparent  focus:outline-none rounded-md px-2 py-3 font-light"
              placeholder="Enter lastname"
              type="lastname"
              name="lastname"
              autoComplete="family-name"
            />
          </div>
        </motion.div>
      </div>

      <div className="flex gap-x-3">
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 3 * 2).animate}
          className=" w-1/2 mt-3"
        >
          <p className="text-hint">Email</p>
          <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
            <BiEnvelope className="text-2xl text-hint" />
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="w-full bg-transparent  focus:outline-none rounded-md px-2 py-3 font-light"
              placeholder="Enter email"
              type="email"
              name="email"
              autoComplete="email"
            />
          </div>
        </motion.div>

        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 4 * 2).animate}
          className=" w-1/2 mt-3"
        >
          <p className="text-hint">Phone</p>
          <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
            <BiPhone className="text-2xl text-hint" />
            <input
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              className="w-full bg-transparent  focus:outline-none rounded-md px-2 py-3 font-light"
              placeholder="Enter phone"
              type="phone"
              name="phone"
              autoComplete="phone"
            />
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={slideIn("up", null).initial}
        whileInView={slideIn("up", 5 * 2).animate}
        className=" mt-3"
      >
        <p className="text-hint">Password</p>
        <div className="flex relative mt-2 py-3 px-3 rounded-sm  justify-center items-center bg-background">
          <BiLock className="text-2xl text-hint" />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full bg-transparent focus:outline-none rounded-md px-2 intrude font-light"
            placeholder="Enter password"
            type={isVisible ? "text" : "password"}
            name="password"
            autoComplete="current-password"
          />
          <button onClick={toggleVisibilty} type="button">
            {/* Hello */}
            {isVisible ? (
              <MdOutlineVisibility className="text-2xl text-hint" />
            ) : (
              <MdOutlineVisibilityOff className="text-2xl text-hint" />
            )}
          </button>
        </div>
      </motion.div>

      <button className="bg-gradient mt-8 w-[100%] py-3 rounded-sm text-white">
        {isLoading ? "Please wait..." : "Signup"}
      </button>

    </form>
  );
}

export default SignupSection;
