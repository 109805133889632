import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payments: [],
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    loadPayment(state, action){
      state.payments = action.payload;
    },
    addPayment(state, action) {
      state.payments.push(action.payload);
    },
    removePayment(state, action) {
      state.payments = state.payments.filter(user => user.id !== action.payload.id);
    },
    updatePayment(state, action) {
      const newData = action.payload;
      const index = state.payments.findIndex(user => user.id === newData.id);
      if (index !== -1) {
        state.payments[index] = { ...state.payments[index], ...newData };
      }
    },
  },
});

export const {loadPayment, addPayment, removePayment, updatePayment } = paymentSlice.actions;
export default paymentSlice.reducer;