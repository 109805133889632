import React, { useState } from "react";
import { BiLock, BiEnvelope } from "react-icons/bi";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { motion } from "framer-motion";
import { slideIn } from "../../../motion";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../../app/slice/auth.slice";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginuser } from "../../../constants/routes.path";

const LoginSection = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!email || !password) {
      toast.error("Please fill in all fields.");
      return;
    }

    toggleLoading();
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    const userData = {
      email,
      password,
    };

    try {
      const response = await axios.post(loginuser, userData);
      const token = response.data.token;
      const data = response.data.data;
      const message = response.data.message;
      dispatch(login(data));
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(data));
      navigate("/dashboard");
      toast.success(message);
    } catch (error) {
      const errorMessage = error.response.data.message;
      toast.error(errorMessage);
    } finally {
      toggleLoading();
    }
  };

  const toggleVisibilty = () => {
    setIsVisible(!isVisible);
  };

  const toggleLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  return (
    <form method="post" onSubmit={handleSubmitEvent}>
      <ToastContainer />

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 1 * 2).animate}
        className=" mt-5"
      >
        <p className="text-hint">Email</p>
        <div className="flex mt-2 py-3 px-3 rounded-sm  justify-center items-center bg-background">
          <BiEnvelope className="text-2xl text-hint" />
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full bg-transparent  focus:outline-none rounded-md px-2  font-light"
            placeholder="Enter email here"
            type="email"
            name="email"
            autoComplete="email"
          />
        </div>
      </motion.div>

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 3 * 2).animate}
        className=" mt-3"
      >
        <p className="text-hint">Password</p>
        <div className="flex relative mt-2 py-3 px-3 rounded-sm  justify-center items-center bg-background">
          <BiLock className="text-2xl text-hint" />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full bg-transparent focus:outline-none rounded-md px-2 intrude font-light"
            placeholder="Enter password"
            type={isVisible ? "text" : "password"}
            name="password"
            autoComplete="current-password"
          />
          <button onClick={toggleVisibilty} type="button">
            {/* Hello */}
            {isVisible ? (
              <MdOutlineVisibility className="text-2xl text-hint" />
            ) : (
              <MdOutlineVisibilityOff className="text-2xl text-hint" />
            )}
          </button>
        </div>
      </motion.div>

      <div className="flex items-center justify-between">
        {" "}
        <motion.div
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" flex items-center mt-4 text-hint font-normal gap-x-3"
        >
          <input
            type="checkbox"
            value={true}
            checked
            onChange={(value) => {
              console.log(value);
            }}
            className=""
          />
          <p>Remember Me</p>
        </motion.div>
        <motion.div
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" flex items-center mt-4 text-hint font-normal gap-x-3"
        >
          <Link to={"/verifyemail"}>Forgotten Password</Link>
        </motion.div>
      </div>

      <motion.button
        initial={slideIn("down", null).initial}
        whileInView={slideIn("down", 0 * 2).animate}
        className="bg-gradient mt-8 w-[100%] py-3 rounded-sm text-white"
        type="submit"
      >
        {isLoading ? "Please wait..." : "Login"}
      </motion.button>
    </form>
  );
};

export default LoginSection;
