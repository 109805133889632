export function formatIntDate(isoString) {
    const date = new Date(isoString);
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    // const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  
    return formattedDate;
  }

 export function formatStringDate(isoString) {
    const date = new Date(isoString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }
