import React, { useState } from "react";
import { motion } from "framer-motion";
import { slideIn } from "../../../motion";
import axios from "axios";
import { acknowledgeDemand, acknowledgeReminder } from "../../../constants/routes.path";
import { ToastContainer, toast } from "react-toastify";
import { AiFillCloseCircle } from "react-icons/ai";


const AknowledgmentSection = () => {
  const [isLoading, setLoading] = useState(false);
  const [noticeId, setNoticeId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [noticeType, setNoticeType] = useState("--- Notice Type ---")
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file);

      // Read the file and generate a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreview(null);
    }
  };

  const clearSelection=()=>{
    setSelectedFile(null)
    setPreview(null)
  }



  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    toggleLoading();

    try {
      if(noticeType === "--- Notice Type ---"){
        toast.error("Notice type must be selected");
        return;
      }

      if(noticeId==="" || phone==="" || name===""){
        toast.error("All fields must be completely filled");
        return;
      }

      if(selectedFile.size > 1024 * 1024){
        toast.error("File Size must not exceed 2MB");
        return;
      }

      const formData = new FormData();
      formData.append('noticeId', noticeId);
      formData.append('phone', phone);
      formData.append('name', name);
      formData.append('noticeType', noticeType);
      formData.append('file', selectedFile);
  
      // await axios.post(paymentproof, formData);

      // console.log(body);

    if(noticeType === "demand"){
      await axios.put(acknowledgeDemand, formData);
    }else{
      await axios.put(acknowledgeReminder, formData);
    }

      toast.success("Proof uploaded succesfuly");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      toggleLoading();
    }
  };

  const toggleLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <form method="post" onSubmit={handleSubmitEvent}>
      <ToastContainer />

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 1 * 2).animate}
        className=" mt-5"
      >
        <p className="text-hint">Notice Id</p>
        <div className="flex py-3 px-3 mt-2 rounded-sm  justify-center items-center bg-background">
          <input
            value={noticeId}
            onChange={(e) => setNoticeId(e.target.value)}
            className="w-full bg-transparent  focus:outline-none rounded-md px-2  font-light"
            placeholder="Enter Demand Notice Id"
            type="text"
            name="noticeId"
            autoComplete="noticeId"
          />
        </div>
      </motion.div>

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 1 * 2).animate}
        className=" mt-5"
      >
        <p className="text-hint">Notice Type</p>
        <div className="flex px-3 mt-2 rounded-sm  justify-center items-center bg-background">
          <select
            onChange={(e) => {
              console.log(e.target.value);
              setNoticeType(e.target.value);
            }}
            className="w-full bg-background mt-2 focus:outline-none rounded-sm py-2 px-2  font-light"
            name="noticeType"
            defaultValue={noticeType}
          >
            <option value={noticeType} disabled> --- Notice Type --- </option>
            <option value={'demand'}>Demand Notice</option>
            <option value={'reminder'}>Reminder Notice</option>
          </select>
        </div>
      </motion.div>

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 2 * 2).animate}
        className=" mt-3"
      >
        <p className="text-hint">Name</p>
        <div className="flex mt-2 py-3 px-3 rounded-sm  justify-center items-center bg-background">
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full bg-transparent  focus:outline-none rounded-md px-2  font-light"
            placeholder="Acknowledger's Name"
            type="name"
            name="name"
            autoComplete="name"
          />
        </div>
      </motion.div>

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 2 * 2).animate}
        className=" mt-3"
      >
        <p className="text-hint">Phone Number</p>
        <div className="flex mt-2 py-3 px-3 rounded-sm  justify-center items-center bg-background">
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full bg-transparent  focus:outline-none rounded-md px-2  font-light"
            placeholder="Acknowledger's Phone"
            type="phone"
            name="phone"
            autoComplete="phone"
          />
        </div>
      </motion.div>

      <motion.div
       initial={slideIn("right", null).initial}
       whileInView={slideIn("right", 4 * 2).animate}

      className="my-4">
        <label className="block text-sm font-medium text-gray-700">
         Upload Signed Copy
        </label>

        <div className="mt-1 flex items-center bg-background py-3">

          <label className="relative cursor-pointer ml-3">
            <span className="text-xs w-[100%] py-2 px-8 bg-gradient text-white rounded-sm">Upload</span>
            <input type="file" className="sr-only"  onChange={handleFileChange} />
          </label>

          <span className="ml-2 text-sm text-gray-500">
          {selectedFile ? selectedFile.name : "No file chosen"}
          </span>

        </div>


        {preview && (
          <div className="mt-2">
            <p className="text-sm font-medium text-gray-700">File Preview: {formatFileSize(selectedFile.size)} <button>{<AiFillCloseCircle onClick={clearSelection} className=" text-red-500"/>}</button></p>
            <img className="mt-1 w-40 h-20 object-cover rounded-md" src={preview} alt="File Preview" />
            
          </div>
        )}
      </motion.div>

      <motion.button
        initial={slideIn("down", null).initial}
        whileInView={slideIn("down", 0 * 2).animate}
        className="bg-gradient mt-3 mb-5 w-[100%] py-3 rounded-sm text-white"
        type="submit"
      >
        {isLoading ? "Please wait..." : "Acknowlege"}
      </motion.button>
    </form>
  );
};

export default AknowledgmentSection;
