import React, { useState, useEffect } from 'react'
import { MdArrowBack, MdArrowForward, MdDelete, MdVisibility } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import PaymentModal from '../../../components/PaymentModal';
import { useSelector } from "react-redux";
import { formatStringDate } from '../../../../utils/date.time';
import authService from '../../../../app/service/auth.service';
import axios from "axios";
import { paymentproofDelete } from '../../../../constants/routes.path';
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { removePayment } from '../../../../app/slice/payment.slice';



const Payment = () => {

    const paymentState = useSelector((state) => state.payment);
    const authentication = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [payments, setPayments] = useState([...paymentState.payments])

    const toggleShowModal = (index) => {
        setShowModal(!showModal);
        setSelectedIndex(index);
    }


    useEffect(() => {
        setPayments(paymentState.payments);
    }, [paymentState.payments]);

    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const paginatedBusinesses = payments.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const search = (query) => {
        const filteredBusinesses = paymentState.payments.filter((eachPayment) =>
            eachPayment.paymentId.toLowerCase().includes(query.toLowerCase()) || eachPayment.Business.organization.toLowerCase().includes(query.toLowerCase())
        );
        setPayments(filteredBusinesses);
    };

    const deleteAccount = async (index) => {

        if( authentication.role !== 2){
            toast.error("You are not authorized");
            return;
        }

        try {
            setLoading(index);
            const response = await axios.post(paymentproofDelete, { proofid: index }, { headers: authService.authHeader() });
            console.log(response);
            const message = response.data.message;
            dispatch(removePayment({ id: index }));
            toast.success(message);
            setLoading(-1);

        } catch (e) {

        }
    }

    return (
        <div className='pl-5 pr-6 w-[100%] flex flex-col space-y-5 overflow-hidden'>

            {/* profile section */}

            <PaymentModal onClose={toggleShowModal} isVisible={showModal} data={payments[selectedIndex]} />

            <ToastContainer />


            <section className={"grid md:grid-cols-2 gap-4 mt-5 mb-2"}>

                <div className="rounded-md  pt-3 bg-white pb-2 md:col-span-2">
                    <div className=" mx-2 flex flex-col md:flex-row items-end space-y-1 md:items-center md:justify-between mb-2">

                        <div className='relative'>
                            <BiSearch className=" absolute top-[13px] left-2 text-gray-400" />
                            <input onChange={(e)=>search(e.target.value)} placeholder='Search' className=' bg-gray-100 py-2 pl-7 rounded-sm' /></div>
                        <div className='flex justify-center items-center space-x-1'>
                            <p>Rows</p>
                            <input placeholder='Search' type='number'

                                value={itemPerPage}
                                onChange={(e) => e.target.value <= 4 ? {} : setItemPerPage(e.target.value)}

                                className=' bg-gray-100 py-2 pl-2 w-14 rounded-sm' />
                        </div>
                    </div>
                    <hr className=" border-grey" />

                    <table className="min-w-full">
                        <thead className=" text-left h-11">
                            <tr className="text-sm h-11 text-hint">
                                <th className=" py-1 px-2">S/N</th>
                                <th className=" py-1 px-2 ">Organization</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Date</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Amount</th>
                                <th className=" py-1 px-2 hidden md:table-cell">PaymentId</th>
                                <th className=" py-1 px-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedBusinesses.map((element, indexOnPage) => {
                                const globalIndex = indexOfFirstItem + indexOnPage + 1;
                                return (
                                    <tr
                                        key={globalIndex}
                                        className={`text-sm h-11 ${indexOnPage % 2 === 0 ? "bg-gray-100" : ""
                                            } text-hint`}
                                    >
                                        <td className=" py-1 pl-4 ">{globalIndex}</td>
                                        <td className=" py-1 px-2 ">{element.Business ? element.Business.organization : ""}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{formatStringDate(element.createdAt)}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell text-green-500"><span className={element.status === 'approved' ? ' text-green-500': 'text-gray-500'}>{element.status}</span></td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.paymentId}</td>

                                        <td className=" py-1 px-2 flex space-x-1">
                                            <button onClick={() => toggleShowModal(globalIndex - 1)} className='bg-primary p-4 rounded-sm text-white flex justify-center items-center gap-2'>View <MdVisibility /></button>
                                            <button onClick={() => deleteAccount(element.id)} className=' bg-red-500 p-4 rounded-sm text-white flex justify-center items-center gap-2'>{isLoading === element.id ? "Deleting.." : "Delete"} <MdDelete /></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                    <table>
                        <tbody>
                            <tr className='flex items-center py-2 px-2 space-x-1'>
                                <td>
                                    <button
                                        onClick={() => paginate(currentPage - 1)}
                                        className={`flex justify-center items-center space-x-2 ${currentPage === 1 ? "disabled" : ""} border-gray border-2 px-2 py-2 rounded-md ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}

                                    >
                                        <MdArrowBack /><p>Previous</p>
                                    </button>

                                </td>
                                {Array.from({ length: Math.ceil(payments.length / itemPerPage) }, (_, index) => (
                                    <td key={index}>
                                        <p
                                            key={index}
                                            onClick={() => paginate(index + 1)}
                                            className={`px-4 py-2 rounded-md cursor-pointer ${currentPage === index + 1 ? 'bg-backgroud text-primary border-2 border-backgroud ' : ''}`}
                                        >
                                            {index + 1}
                                        </p>
                                    </td>
                                ))}

                                <td>
                                    <button
                                        onClick={() => paginate(currentPage + 1)}
                                        className={`flex ${currentPage === Math.ceil(payments.length / itemPerPage) === true ? "hidden" : ""} justify-center items-center space-x-2 border-gray border-2 px-2 py-2 rounded-md ${currentPage === Math.ceil(payments.length / itemPerPage) ? 'cursor-not-allowed opacity-50' : ''}`}>
                                        <p>Next</p><MdArrowForward />
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>



        </div>
    )
}

export default Payment



