import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businesses: [],
  categories: []
};

const businessSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    loadBusiness(state, action){
      state.businesses = action.payload;
    },
    loadCategories(state, action){
      state.categories = action.payload;
    },
    addBusiness(state, action) {
      state.businesses.unshift(action.payload);
    },
    removeBusiness(state, action) {
      const { id } = action.payload;
      state.businesses = state.businesses.filter(business => business.id !== id);
    },
    updateBusinessSlice(state, action) {
      const newData = action.payload;


      const index = state.businesses.findIndex(thisbiz => thisbiz.id === newData.id);
      if (index !== -1) {
        state.businesses[index] = { ...state.businesses[index], ...newData };
      }
    },
  },
});


export const {loadBusiness, loadCategories, addBusiness, removeBusiness, updateBusinessSlice } = businessSlice.actions;
export default businessSlice.reducer;