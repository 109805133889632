
import React from "react";
import { RiCloseFill } from 'react-icons/ri'
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import { formatStringDate } from '../../utils/date.time';
import { BiCheckCircle, BiDownload } from "react-icons/bi";
import download from 'downloadjs';



const DemandModal = ({ onClose, isVisible, data }) => {


    const handleDownload = async (url) => {

        try {
            const response = await fetch(url);
            const blob = await response.blob();
            download(blob, 'downloaded_image.jpg');
        } catch (error) {
            console.error('Error downloading image:', error);
        }

    }

    return (

        <div className={isVisible ? 'hidden' : 'fixed z-50 w-[100%] md:w-[94%] h-[85%] bg-background bg-opacity-80  flex justify-center items-center'}>


            <form method='post' className={'w-[90%] mr-10 md:mr-0 md:w-[40%] pb-10  rounded-md md:left-[25%]  bg-white absolute'}>
                <div className=' flex items-center justify-between px-5 py-3'>
                    <p className=' text-hint font-semibold'>Demand Notice Details</p>
                    <button onClick={onClose} type="button"><RiCloseFill className=' text-red-500' /></button>
                </div>
                <hr className=' my-2' />


                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5 py-2 bg-background  px-2"
                >
                    <p className="text-hint">Organization: <span className=' font-semibold'>{data ? data.Business.organization : ""}</span></p>
                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5  mt-3  py-2 px-2"
                >
                    <p className="text-hint flex gap-x-10">Delivered: {!data ? "" : data.name ? <div className=' text-green-600 text-2xl'> <BiCheckCircle className=' mr-16' /></div> : <div className='text-2xl'> <BiCheckCircle className=' mr-16' /></div>}</p>
                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5 bg-background py-2 px-2"
                >
                    <p className="text-hint flex gap-x-9">Reminded: {!data ? "" : data.remindername ? <div className=' text-green-600 text-2xl'> <BiCheckCircle className=' mr-16' /></div> : <div className='text-2xl'> <BiCheckCircle className=' mr-16' /></div>}</p>

                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5  mt-3  py-2 px-2"
                >
                    <p className="text-hint">Demand ID: {!data ? "" : data.noticeId}</p>
                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5 bg-background py-2 px-2"
                >
                    <p className="text-hint">Date: {data ? formatStringDate(data.createdAt) : ""}</p>
                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5  mt-3  py-2 px-2"
                >
                    <p className="text-hint">Status: {!data ? "" : <span className=" capitalize text-green-500">{data.status}</span>}</p>
                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5 bg-background py-2 px-2"
                >
                    <p className="text-hint">Notice Received By: {data ? data.name : ""}</p>
                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5  mt-3  py-2 px-2"
                >
                    <p className="text-hint">Reminder Received By: {data ? data.remindername : ""}</p>
                </motion.div>


                {/* <button type="button" onClick={()=>handleDownload(data.signedDemand)}>Download Image</button> */}

                <div className="flex items-center space-x-2 md:space-x-5 mt-5 px-5 justify-end">
                    <motion.div

                     initial={slideIn("down", null).initial}
                     whileInView={slideIn("down", 1 * 2).animate}
                    >
                        <button type="button" onClick={() => handleDownload(data.signedDemand)} className={data?.signedDemand ? "text-sm flex items-center gap-x-2 text-white bg-gradient-green px-2 py-3 font-light rounded-md" : "hidden"}>
                            <BiDownload />
                            Signed Notice</button>
                    </motion.div>

                    <motion.div
                     initial={slideIn("down", null).initial}
                     whileInView={slideIn("down", 3 * 2).animate}
                    >
                        <button type="button" onClick={() => handleDownload(data.signedReminder)} className={data?.signedReminder ? "text-sm flex items-center gap-x-2 text-white bg-gradient px-2 py-3 font-light rounded-md":"hidden"}>
                            <BiDownload />
                            Signed Reminder</button>
                    </motion.div>
                </div>




            </form>

        </div>


    )
}

export default DemandModal