import { appImages } from "../../data";
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import UploadSection from "./components/UploadSection";
import { BiCheckCircle, BiLogIn } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function ProofUpload() {

  let navigate = useNavigate();

  const acknowlege = ()=>{
    navigate('/acknowledgement');
 }

  const backtoLogin = ()=>{
    navigate('/authentication')
  }

  return (
    <div className=" flex flex-row">


      <div className=" bg-gradient overflow-y-scroll overflow-x-hidden h-screen justify-between w-[45%] px-10 pt-5 hidden md:flex flex-col">
        <motion.h3
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-white"
        >
          <b>BRIDGE</b>NUMERIC
        </motion.h3>
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 0 * 2).animate}
        >
          <img src={appImages.avater} alt="" className=" w-2/3 mt-1" />
        </motion.div>

        <motion.h1
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 1 * 2).animate}
          className=" text-5xl font-medium text-white"
        >
          Let's setup <br /> your Operating <br /> Agreement
        </motion.h1>

        <motion.div
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 3 * 2).animate}
          className=" mt-10 w-[90%]"
        >
          <p className="text-white mb-10">
            Remote solution for seamless organizational data collection,
            validation and management across the nation.
          </p>
        </motion.div>
      </div>

      {/* Right Hand Side */}
      <div className={`mx-5 md:mx-20 }`}>

      <div className=" flex items-center justify-start md:justify-end mt-10 space-x-3">
        <motion.button
          onClick={backtoLogin}
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-white mb-10 flex items-center bg-primary justify-center gap-x-3 border-2 border-primary py-2 px-2 rounded-md"
        >
        <BiLogIn/>
         Return to Login
        </motion.button>

        <motion.button
          onClick={acknowlege}
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-primary mb-10 flex items-center justify-center gap-x-3 border-2 border-primary py-2 px-2 rounded-md"
        >
        <BiCheckCircle/>
          Acknowledgment
        </motion.button>
        </div>

        <img src={appImages.logo} alt="" className="  w-44 mt-10" />
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 1 * 2).animate}
        >
          <h1 className="text-3xl font-semibold mt-5">Proof of Payment</h1>
          <p className="text-hint mt-1">
            Kindly validate and upload your proof of payment below.
          </p>
        </motion.div>
        <UploadSection />

      </div>
    </div>
  );
}

export default ProofUpload;
