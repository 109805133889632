import axios from 'axios'
import authService from './auth.service';
import { payments } from '../../constants/routes.path';
import { loadPayment } from '../slice/payment.slice';

const fetchPayment = async (dispatch) => {

  try {
    const response =  await axios.get(payments, { headers: authService.authHeader()});
    const payment = response.data;
    dispatch(loadPayment(payment.data));
    localStorage.setItem("payments", JSON.stringify(payment.data));

  } catch (error) {

    console.log("this error", error);

  } finally {
    // pass
  }

};


export default fetchPayment