import React, { useState, useEffect } from 'react'
import { IoAddSharp } from 'react-icons/io5';
import { MdArrowBack, MdArrowForward, MdDelete, MdEditRoad } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import OrganizationModal from '../../components/OrganizationModal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { createDemand, deleteBusiness } from '../../../constants/routes.path';
import {removeBusiness} from '../../../app/slice/business.slice'
import { useSelector, useDispatch } from 'react-redux';
import authService from '../../../app/service/auth.service';
import { GrDocument } from 'react-icons/gr';
import { Circles } from "react-loader-spinner";
import { addDemand } from '../../../app/slice/demand.slice';

const Organization = () => {

    const businessInstances = useSelector((state) => state.business);
    const authentication = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();


    const [showModal, setShowModal] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10)
    const [bcreate, setBcreate] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [loading, setLoading] = useState(999999999999);
    const [businesses, setBusinesses] = useState([...businessInstances.businesses])

    // const businesses = businessInstances.businesses;

   
    useEffect(() => {
        setBusinesses(businessInstances.businesses);
    }, [businessInstances.businesses]);


    const search = (query) => {
        const filteredBusinesses = businessInstances.businesses.filter((business) =>
           business.organization.toLowerCase().includes(query.toLowerCase()) || business.email.toLowerCase().includes(query.toLowerCase()) ||  business.Account.firstname.toLowerCase().includes(query.toLowerCase()) ||  business.Account.lastname.toLowerCase().includes(query.toLowerCase())
        );
        setBusinesses(filteredBusinesses);
    };

    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
        filterMine(isChecked);
    };

    const filterMine = (mine) => {
        if (!mine) {
            const filteredDemands = businessInstances.businesses.filter((business) =>
                business.Account.firstname.toLowerCase().includes(authentication.firstname) && business.Account.lastname.toLowerCase().includes(authentication.lastname)
            );
            console.log("filteredOrganization", filteredDemands);
            setBusinesses(filteredDemands);
        } else {
            const filtered = businessInstances.businesses;
            console.log("filtered", filtered);
            setBusinesses(filtered);
        }

    };

    

    const toggleShowModal = (index) => {

        console.log(businessInstances.businesses[index])

        if(index === null){
            setSelectedIndex(999999999999999);
            setBcreate(true);

        }else{

            if( authentication.role === 0){
                toast.error("You are not authorized");
                return;
            }
            
            setSelectedIndex(index);
            setBcreate(false); 
        }

        setShowModal(!showModal);
    }

    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const paginatedBusinesses = businesses.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const deleteOrganization= async(index)=>{

        if( authentication.role !== 2){
            toast.error("You are not authorized");
            return;
        }

        const url = deleteBusiness+`/${index}`;
        const response = await axios.delete(url, { headers: authService.authHeader() });
        const message = response.data.message;
        dispatch(removeBusiness({ id: index }));
        toast.success(message);
    }

    const generateDemandNotice= async(index)=>{

        setLoading(index);
        const body = {
            "meansOfDispatch": "Door Delivery",
            "business": index
          };

    try {


        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await delay(500);
       
        const response = await axios.post(createDemand, body, {headers: authService.authHeader()});
        const message = response.data.message;
        console.log(message, response);
        const data = response.data.data;
        dispatch(addDemand(data));
        setLoading(999999999999999);
        toast.success(message);
  
      } catch (error) {
  
        const errorMessage = error.response.data.message
        setLoading(999999999999999);
        toast.error(errorMessage);
  
      } finally {
       
      }
    }
    


    return (
        <div className='pl-5 pr-6 w-[100%] flex flex-col space-y-5 overflow-hidden'>

            {/* profile section */}

            <OrganizationModal onClose={()=> toggleShowModal(null)} data={businesses[selectedIndex]} isVisible={showModal} isCreate={bcreate} />
            <ToastContainer/>


            <div className='flex justify-end'>
                <button onClick={()=> toggleShowModal(null)} className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient p-4 font-light rounded-md">
                    <IoAddSharp />
                    New Business
                </button>
            </div>


            <section className="grid md:grid-cols-2 gap-4 mt-5 mb-2">

                <div className="rounded-md  pt-3 bg-white pb-2 md:col-span-2">
                    {/* FILTER */}
                <div className='flex items-center space-x-4 justify-end mb-5 w-full px-5'>
                    <p>Filter By Me</p>
                    <label className="toggle-container">
                        <input
                            type="checkbox"
                            className="toggle-checkbox"
                            checked={isChecked}
                            onChange={toggleCheckbox}
                        />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
                    <div className=" mx-2 flex flex-col md:flex-row items-end space-y-1 md:items-center md:justify-between mb-2">

                        <div className='relative'>
                            <BiSearch className=" absolute top-[13px] left-2 text-gray-400" />
                            <input onChange={(e)=> search(e.target.value)} placeholder='Search' className=' bg-gray-100 py-2 pl-7 rounded-sm' /></div>
                        <div className='flex justify-center items-center space-x-1'>
                            <p>Rows</p>
                            <input placeholder='Search' type='number'

                                value={itemPerPage}
                                onChange={(e) => e.target.value <= 4 ? {} : setItemPerPage(e.target.value)}

                                className=' bg-gray-100 py-2 pl-2 w-14 rounded-sm' />
                        </div>
                    </div>
                    <hr className=" border-grey" />

                    <table className="min-w-full">
                        <thead className=" text-left h-11">
                            <tr className="text-sm h-11 text-hint">
                                <th className=" py-1 px-2">S/N</th>
                                <th className=" py-1 px-2 ">Organization</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Phone</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Group</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Amount</th>
                                <th className=" py-1 px-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedBusinesses.map((element, indexOnPage) => {
                                const globalIndex = indexOfFirstItem + indexOnPage + 1;
                                return (
                                    <tr
                                        className={`text-sm h-11 ${indexOnPage % 2 === 0 ? "bg-gray-100" : ""
                                            } text-hint`} key={globalIndex}
                                    >
                                        <td className=" py-1 pl-4 ">{globalIndex}</td>
                                        <td className=" py-1 px-2 ">{element.organization}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.phone}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.Category ? element.Category.group : ""}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.price}</td>
                                        <td className=" py-1 px-2 flex space-x-1">
                                            <button onClick={()=> toggleShowModal(globalIndex-1)} className='bg-primary px-4 rounded-sm text-white flex items-center gap-x-2'><span className='hidden md:block'>Update</span><MdEditRoad /></button>
                                            <button onClick={()=> generateDemandNotice(element.id)} className=' bg-yellow-700 p-2 rounded-sm text-white cursor-pointer'>{
                                                 loading === element.id ?
                                                 <span className='flex items-center gap-x-2'> <Circles
                                                 height="15"
                                                 width="15"
                                                 color="#ffffff"
                                                 ariaLabel="circles-loading"
                                                 wrapperStyle={{}}
                                                 wrapperClass=""
                                                 visible={true}
                                                 />
                                                 Generating...
                                                 </span>
                                                 :
                                              <span className='flex items-center gap-x-2'> <GrDocument className=' hidden md:block'/> Generate DN</span>}</button>
                                        <button onClick={()=> deleteOrganization(element.id)} className='bg-red-500  p-4 rounded-sm text-white flex items-center gap-x-2'><span className='hidden md:block'>Delete</span><MdDelete /></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                <table>
                    <tbody>
                    <tr className='flex items-center py-2 px-2 space-x-1'>
                        <td>
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                className={`flex justify-center items-center space-x-2 ${currentPage === 1 ? "disabled" : ""} border-gray border-2 px-2 py-2 rounded-md ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}

                            >
                                <MdArrowBack /><p>Previous</p>
                            </button>

                        </td>
                        {Array.from({ length: Math.ceil(businesses.length / itemPerPage) }, (_, index) => (
                            <td key={index}>
                                <p
                                    key={index}
                                    onClick={() => paginate(index + 1)}
                                    className={`px-4 py-2 rounded-md cursor-pointer ${currentPage === index + 1 ? 'bg-backgroud text-primary border-2 border-backgroud ' : ''}`}
                                >
                                    {index + 1}
                                </p>
                            </td>
                        ))}

                        <td>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                className={`flex ${currentPage === Math.ceil(businesses.length / itemPerPage) === true ? "hidden" : ""} justify-center items-center space-x-2 border-gray border-2 px-2 py-2 rounded-md ${currentPage === Math.ceil(businesses.length / itemPerPage) ? 'cursor-not-allowed opacity-50' : ''}`}>
                                <p>Next</p><MdArrowForward />
                            </button>
                        </td>

                    </tr>
                    </tbody>
                </table>
                </div>
            </section>




        </div>
    )
}

export default Organization