import axios from 'axios'
import authService from './auth.service';
import { demands } from '../../constants/routes.path';
import { loadDemand } from '../slice/demand.slice';

const fetchBusiness = async (dispatch) => {


  try {

    const response =  await axios.get(demands, { headers: authService.authHeader()});
    const demand = response.data;
    dispatch(loadDemand(demand.data));
    localStorage.setItem("demands", JSON.stringify(demand.data));
  } catch (error) {

    console.log("this error", error);

  } finally {
    // pass
  }

};


export default fetchBusiness