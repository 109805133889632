

/*------------------------------------------
----------- APP IMAGES
------------------------------------------*/

import avater from "./assets/avater.svg";
import banner from "./assets/banner.png";
import logo from "./assets/logo.png";
import coatofarms from "./assets/coatofarms.png"
import bottom from "./assets/bottom.png"
import center from "./assets/center.png"
import stamp from "./assets/stamp.png";


import { RiAdminLine } from "react-icons/ri";
import { GrDocumentUser } from "react-icons/gr";
import { SlWallet } from "react-icons/sl";
import { GrOrganization } from "react-icons/gr";
import {
    AiOutlineDashboard,
    AiOutlineUser,
  } from "react-icons/ai";

export const appImages = {
    avater:avater,
    banner:banner,
    logo:logo,
    coatofarms: coatofarms,
    bottom,
    center,
    stamp
}


export const sidebarData = [
    { name: "Dashboard", icon: <AiOutlineDashboard /> },
    { name: "User Profile", icon: <AiOutlineUser /> },
    { name: "Accounts", icon: <RiAdminLine /> },
    { name: "Organizations", icon: <GrOrganization /> },
    { name: "Demand Notice", icon: <GrDocumentUser /> },
    { name: "Payments", icon: <SlWallet /> },
  ];