import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
};

const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    loadAccounts(state, action){
      state.users = action.payload;
    },
    addAccount(state, action) {
      state.users.unshift(action.payload);
    },
    removeAccount(state, action) {
      const { id } = action.payload;
      state.users = state.users.filter(user => user.id !== id);
    },
    updateAccount(state, action) {
      const { id, data } = action.payload;
      const index = state.users.findIndex(user => user.id === id);
      if (index !== -1) {
        state.users[index] = data;
      }
    },
  },
});


export const {loadAccounts, addAccount, removeAccount, updateAccount } = accountSlice.actions;
export default accountSlice.reducer;