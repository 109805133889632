
import axios from 'axios';

const BASEURL = "http://localhost:8080";

const authService = {
  login: async (userData) => {
    try {
      const user = await axios.post(`${BASEURL}/login`, userData);
      return user.data; // Assuming your server returns user data upon successful login
    } catch (error) {
      throw new Error(error.response.data.message); // Throw an error with the error message from the server
    }
  },

  logout: async () => {
    // You can implement logout logic here if needed
    // For example, clearing user data from localStorage
  },

  authHeader: () => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      return { 'authorization': `Barear ${token}` };
    } else {
      return {};
    }
  }
};

export default authService;
