import React, { useState, useEffect } from 'react'
import ProfileModal from '../../components/ProfileModal';
import { IoAddSharp } from 'react-icons/io5';
import { MdArrowBack, MdArrowForward, MdDelete, MdEditRoad } from "react-icons/md";
import { BiCheck, BiSearch } from "react-icons/bi";
import { MdDisabledVisible } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { approveAccount, deleteUserAccount } from '../../../constants/routes.path';
import authService from '../../../app/service/auth.service';
import { updateAccount, removeAccount } from '../../../app/slice/account.slice';
import { ToastContainer, toast } from 'react-toastify';
import { Circles } from "react-loader-spinner";


const Administrator = () => {

    const dispatch = useDispatch();

    const accountInstances = useSelector((state) => state.account.users);
    const authentication = useSelector((state) => state.auth.user);


    const [showModal, setShowModal] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10)
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(999999999999);
    const [users, setUsers] = useState([...accountInstances]);

    useEffect(() => {
        setUsers(accountInstances);
    }, [accountInstances]);

    const search = (query) => {
        const filteredBusinesses = accountInstances.filter((business) =>
           business.firstname.toLowerCase().includes(query.toLowerCase()) || business.lastname.toLowerCase().includes(query.toLowerCase()) || business.phone.includes(query) 
        );
        setUsers(filteredBusinesses);
    };
    

    const verifyNewAccount= async(index)=>{

        if( authentication.role !== 2){
            toast.error("You are not authorized");
            return;
        }

        setLoading(index);
        const response = await axios.post(approveAccount, {accountId: index}, { headers: authService.authHeader() });
        console.log(response.data.data);
        const message = response.data.message;
        const data = response.data.data;
        dispatch(updateAccount({ id: data.id, data }));
        toast.success(message);
        setLoading(999999999999);
    }


    const deleteAccount= async(index)=>{

        if( authentication.role !== 2){
            toast.error("You are not authorized");
            return;
        }

        setLoading(index);
        const response = await axios.post(deleteUserAccount, {accountId: index}, { headers: authService.authHeader() });
        const message = response.data.message;
        dispatch(removeAccount({ id: index }));
        toast.success(message);
        setLoading(-1);
    }


    const toggleShowModal = (index) => {
        
        if( authentication.role !== 2){
            toast.error("You are not authorized");
            return;
        }

        setSelectedIndex(index);
        setShowModal(!showModal);
    }


    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentUsers = users.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='pl-5 pr-6 w-[100%] flex flex-col space-y-5 overflow-hidden'>

            {/* profile section */}

            <ProfileModal onClose={()=> toggleShowModal(0)} isVisible={showModal} data={users[selectedIndex]} />
            <ToastContainer/>

            <div className='flex justify-end'>
                <button onClick={()=> toggleShowModal()} className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient p-4 font-light rounded-md">
                    <IoAddSharp />
                    Add User
                </button>
            </div>


            <section className="grid md:grid-cols-2 gap-4 mt-5 mb-2">

                <div className="rounded-md  pt-3 bg-white pb-2 md:col-span-2">
                    <div className=" mx-2 flex flex-col md:flex-row items-end space-y-1 md:items-center md:justify-between mb-2">

                        <div className='relative'>
                            <BiSearch className=" absolute top-[13px] left-2 text-gray-400" />
                            <input onChange={(e)=> search(e.target.value)} placeholder='Search' className=' bg-gray-100 py-2 pl-7 rounded-sm' /></div>
                        <div className='flex justify-center items-center space-x-1'>
                            <p>Rows</p>
                            <input placeholder='Search' type='number'

                                value={itemPerPage}
                                onChange={(e) => e.target.value <= 4 ? {} : setItemPerPage(e.target.value)}

                                className=' bg-gray-100 py-2 pl-2 w-14 rounded-sm' />
                        </div>
                    </div>
                    <hr className=" border-grey" />

                    <table className="min-w-full">
                        <thead className=" text-left h-11">
                            <tr className="text-sm h-11 text-hint">
                                <th className=" py-1 px-2">S/N</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Firstname</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Lastname</th>
                                <th className=" py-1 px-2 ">Email</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Phone</th>
                                <th className=" py-1 px-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentUsers.map((element, indexOnPage) => {
                                const globalIndex = indexOfFirstItem + indexOnPage + 1;
                                return (
                                    <tr
                                        key={globalIndex}
                                        className={`text-sm h-11 ${indexOnPage % 2 === 0 ? "bg-gray-100" : ""
                                            } text-hint`}

                                    >
                                        <td className=" py-1 px-2 ">{globalIndex}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.firstname}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.lastname}</td>
                                        <td className=" py-1 px-2">
                                        {element.email.length > 15 ? `${element.email.substring(0, 15)}...` : element.email}

                                        </td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.phone}</td>
                                        <td className=" py-1 px-2 flex space-x-1">
                                            <button onClick={()=>toggleShowModal(globalIndex-1)} className='bg-primary p-4 rounded-sm text-white flex items-center gap-x-2'><span className='hidden md:block'>Update</span> <MdEditRoad /></button>
                                            <button onClick={()=> verifyNewAccount(element.id)} className={`${element.verified? "bg-green-600": "bg-yellow-700"} p-4 rounded-sm text-white`}>{
                                            loading === element.id ? <Circles
                                            height="15"
                                            width="15"
                                            color="#ffffff"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            /> :
                                             element.verified ? <div className='flex items-center gap-x-6'><span className='hidden md:block'>Approved</span> <BiCheck /></div> : <div className='flex items-center gap-x-2'><span className='hidden md:block'>Unapproved</span> <MdDisabledVisible /></div> 
                                            
                                            }</button>
                                            <button onClick={()=> deleteAccount(element.id)} className='bg-red-500 p-4 rounded-sm text-white flex items-center gap-x-2'><span className='hidden md:block'>Delete</span><MdDelete /></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>

                    <table>

                      <tbody>
                      <tr className='flex items-center py-2 px-2 space-x-1'>
                            <td>
                                <button
                                    onClick={() => paginate(currentPage - 1)}
                                    className={`flex justify-center items-center space-x-2 ${currentPage === 1 ? " disabled" : ""} border-gray border-2 px-2 py-2 rounded-md ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}

                                >
                                    <MdArrowBack /><p>Previous</p>
                                </button>
                            </td>

                            {Array.from({ length: Math.ceil(users.length / itemPerPage) }, (_, index) => (
                                <td  key={index}>
                                    <p
                                        onClick={() => paginate(index + 1)}
                                        className={`px-4 py-2 rounded-md cursor-pointer ${currentPage === index + 1 ? 'bg-backgroud text-primary border-2 border-backgroud ' : ''}`}
                                    >
                                        {index + 1}
                                    </p>
                                </td>
                            ))}

                            <td>
                                <button
                                    onClick={() => paginate(currentPage + 1)}
                                    className={`flex ${currentPage === Math.ceil(users.length / itemPerPage) === true ? "hidden" : ""} justify-center items-center space-x-2 border-gray border-2 px-2 py-2 rounded-md ${currentPage === Math.ceil(users.length / itemPerPage) ? 'cursor-not-allowed opacity-50' : ''}`}>
                                    <p>Next</p><MdArrowForward />
                                </button>
                            </td>

                        </tr>
                      </tbody>

                    </table>
                
                </div>
            </section>




        </div>
    )
}

export default Administrator