import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { slideIn, zoomIn } from "../../../motion";

const Validation = () => {
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]); // Four-digit OTP
  const refs = [useRef(), useRef(), useRef(), useRef()]; // Refs for each input field

  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    toggleLoading();
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);
    toggleLoading();
    console.log();
  };

  const toggleLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto-focus on the next input field
    if (value !== "" && index < 3) {
      refs[index + 1].current.focus();
    }
  };

  return (
    <form method="post" onSubmit={handleSubmitEvent}>
      <div className=" mt-16"
      >
        <div className="flex relative mt-2 py-3 space-x-7 rounded-sm justify-around items-center">
          {otp.map((digit, index) => (
            <motion.div
              initial={zoomIn(index, null).initial}
              whileInView={zoomIn(index, null).animate}
              className="w-1/4text-center border border-gray-300 rounded focus:outline-none"
            >
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                ref={refs[index]} // Set the ref for each input field
                className="w-[100%] text-center  p-3 bg-background "
              />
            </motion.div>
          ))}
        </div>
      </div>

      <motion.button
        initial={slideIn("down", null).initial}
        whileInView={slideIn("down", 0 * 2).animate}
        className="bg-gradient mt-8 w-[100%] py-3 rounded-sm text-white"
        type="submit"
      >
        {isLoading ? "Please wait..." : "Verify"}
      </motion.button>
    </form>
  );
};

export default Validation;
