import React, { useState, useEffect } from "react";
import { appImages, sidebarData } from "../../data";
import { AiOutlineMenu, AiFillCloseCircle } from "react-icons/ai";
// import { IoNotificationsOutline } from "react-icons/io5";
import Home from "./pages/Home";
import { BiLogOut, BiSearch } from "react-icons/bi";
import Profile from "./pages/Profile";
import Administrator from "./pages/Administrator";
import Organization from "./pages/Organization";
import Demand from "./pages/Demand/Demands";
import Payment from "./pages/Payment/Payment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { logout } from '../../app/slice/auth.slice';
import { useDispatch } from "react-redux";
import fetchAccounts from "../../app/service/user.servicie";
import businessFetch from "../../app/service/business.service";
import fetchDemands from '../../app/service/demand.service';
import fetchPayments from '../../app/service/payment.service';
import { next } from "../../app/slice/app.slice";


function Dashboard() {

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const authentication = useSelector((state) => state.auth);
  const appstate = useSelector((state) => state.app);

  useEffect(() => {

    const fetchData = async () => {
      await fetchAccounts(dispatch);
      await businessFetch.fetchBusiness(dispatch);
      await fetchDemands(dispatch);
      await fetchPayments(dispatch);
      await businessFetch.categoryPricing(dispatch)
    };

    const loadData = async () => {
      if (!authentication.user) {
        navigate('/authentication');
      } else {
        await fetchData();
      }
    };

    loadData();

  }, [navigate, authentication.user, dispatch]);


  const [expand, setExpand] = useState(true);
  const [mExpand, setMExpand] = useState(false);


  const logoutUseOut = () => {
    dispatch(logout());
    navigate('/authentication');
  }

  const toggleTab = async (index) => {

    if (index === 4) {
      console.log("fetching for demand notice")
      await fetchDemands(dispatch);
    }

    if (index === 5) {
      console.log("fetching for paymanent data")
      await fetchPayments(dispatch);
    }

    dispatch(next(index));
  };

  const toggleExpand = (index) => {
    setExpand(!expand);
  };

  const toggleMExpand = (index) => {
    setMExpand(!mExpand);
  };

  const pages = [<Home />, <Profile />, <Administrator />, <Organization />, <Demand />, <Payment />];
  const titles = ["Dashboard", "User Profile", "Accounts", "Organization", "Demand Notice", "Payment"]

  return (
    <div>
      <nav
        className={`fixed z-20 flex px-5 items-center justify-between w-full h-14 bg-white`}
      >
        <div className="flex justify-center items-center">
          {/* Mobile Only */}
          <button onClick={toggleMExpand} className="block md:hidden mr-5">
            {mExpand ? (
              <AiFillCloseCircle className=" text-red-500" />
            ) : (
              <AiOutlineMenu />
            )}
          </button>

          {/* Web Only */}
          <div className="hidden md:flex justify-between items-center ">
            <img
              src={appImages.logo}
              alt=""
              className={expand ? " h-10 md:h-6 block " : "hidden"}
            />
            <button
              onClick={toggleExpand}
              className={expand ? "ml-16 mr-9" : "mr-7"}
            >
              {expand ? (
                <AiFillCloseCircle className=" text-red-500 text-2xl" />
              ) : (
                <AiOutlineMenu />
              )}
            </button>
          </div>

          <h2 className=" font-semibold mr-8">{titles[appstate.sidebar]}</h2>
          <div className=" relative hidden md:block">
            <BiSearch className=" absolute top-2 left-2 text-hint" />
            <input
              className=" py-2 font-normal text-xs rounded-sm pl-7 pr-2 bg-background"
              id="search"
              type="text"
              placeholder="Type to search"
            />
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="flex flex-col text-sm items-end justify-center mr-3">
            <p className=" font-semibold text-sm">{authentication.user.firstname}</p>
            <p>{`${authentication.user.role === 0 ? "Staff" : "Admin"} `}</p>
          </div>
          <div className=" w-8 h-8 rounded-3xl bg-slate-200 mr-4 overflow-hidden content-center">

            <span className="flex justify-center mt-1 items-end font-semibold uppercase">
              {authentication.user.firstname[0]}{authentication.user.lastname[0]}
            </span>
            {/* <img
              src="https://media.istockphoto.com/id/1372641621/photo/portrait-of-a-businessman-on-gray-background.webp?b=1&s=170667a&w=0&k=20&c=Yyi5slaeNpq_HPcfgy1305VpJSwerPm_s7mTz_bBk6c="
              alt=""
            /> */}
          </div>
          {/* 
          <div className="relative">
            <IoNotificationsOutline className=" text-2xl" />
            <span className="h-2 w-2 bg-red-500 absolute top-[2px] right-1 rounded-md"></span>
          </div> */}
        </div>
      </nav>
      <div className="h-screen w-screen pt-[50px] bg-background flex">

        {/* sidebar web only */}
        <div className={`hidden md:block bg-white ${expand ? "w-[220px]" : "w-[50px]"} `}>
          <div className=" mt-5">
            {sidebarData.map((element, index) => (
              <button
                onClick={() => toggleTab(index)}
                key={index}
                className={`flex w-[100%]  items-center py-3 mb-1 ${appstate.sidebar === index
                    ? "text-white bg-gradient"
                    : " text-gray-600 bg-transparent"
                  } ${expand ? "space-x-3 px-2" : " justify-center items-center"}`}
              >
                {element.icon}
                <p className={expand ? "block" : "hidden"}>{element.name}</p>
              </button>
            ))}

            <button onClick={logoutUseOut} className={`flex  ${expand ? "px-2 gap-3 items-center" : "justify-center text-xl"} mt-[45%] bg-gradient-red w-full text-white  py-3 `}><BiLogOut /> <span className={expand ? "" : "hidden"}>Logout</span></button>
          </div>


        </div>

        {/* sidebar mobile only */}
        <div className={`absolute h-full z-10 w-[220px] bg-white ${mExpand ? "left-0" : "-left-96"} `}>
          <div className=" mt-5">
            {sidebarData.map((element, index) => (
              <button
                onClick={() => toggleTab(index)}
                key={index}
                className={`flex w-[100%] items-center space-x-3 py-3 pl-5 mb-1 ${appstate.sidebar === index
                    ? "text-white bg-gradient"
                    : " text-gray-600 bg-transparent"}`}
              >
                {element.icon}
                <p className={"block"}>{element.name}</p>
              </button>
            ))}

            <button onClick={logoutUseOut} className={`flex  ${expand ? "px-2 gap-3 items-center" : "justify-center text-xl"} mt-[45%] bg-gradient-red w-full text-white  py-3 `}><BiLogOut /> <span className={expand ? "" : "hidden"}>Logout</span></button>

          </div>
        </div>


        <div className="w-[100%] overflow-scroll pt-5">
          {pages[appstate.sidebar]}
        </div>
      </div>
    </div>

    // <div className="flex bg-background h-screen ">
    //   {/* left side */}

    //   <div
    //     className={` absolute bg-white md:static ${
    //       expand ? "w-[180px] md:w-[20%]" : "w-[50px] -left-40"
    //     } bg-transparent pt-5`}
    //   >
    //     <div className="flex  justify-between items-center px-3">
    //       <img
    //         src={appImages.logo}
    //         alt=""
    //         className={expand ? " h-10 md:h-6 block" : "hidden"}
    //       />
    //       <button onClick={toggleExpand}>
    //         {expand ? (
    //           <AiFillCloseCircle className=" text-red-500" />
    //         ) : (
    //           <AiOutlineMenu />
    //         )}
    //       </button>
    //     </div>

    //     {/* <p className="text-hint font-light text-sm">Account</p> */}

    //     <div className=" mt-8">
    //       {sidebarData.map((element, index) => (
    //         <button
    //           onClick={() => toggleTab(index)}
    //           key={index}
    //           className={`flex w-[100%]  items-center py-2 mb-1 ${
    //             activeIndex === index
    //               ? "text-white bg-gradient"
    //               : " text-gray-600 bg-transparent"
    //           } ${expand ? "space-x-3 px-2" : " justify-center items-center"}`}
    //         >
    //           {element.icon}
    //           <p className={expand ? "block" : "hidden"}>{element.name}</p>
    //         </button>
    //       ))}
    //     </div>
    //   </div>

    //   {/* right side */}

    //   <div className=" w-[100%]">
    //     <nav className={`fixed flex px-5 items-center justify-between h-14 ${expand ? "w-[84%] pr-10":"w-[97%] pr-14" } bg-white`}>
    //       <div className="flex justify-center items-center">
    //         <button onClick={toggleExpand}>
    //           <AiOutlineMenu className="block md:hidden mr-5" />
    //         </button>

    //         <h2 className=" font-semibold mr-8">Dashboard</h2>
    //         <input
    //           className=" hidden md:block py-1 font-normal rounded-sm px-2 bg-background"
    //           type="text"
    //           placeholder="Type to search"
    //         />
    //       </div>

    //       <div className="flex justify-center items-center">
    //         <div className="flex flex-col text-sm items-end justify-center mr-3">
    //           <p className=" font-semibold text-sm">William Mark</p>
    //           <p>Admin</p>
    //         </div>
    //         <div className=" w-8 h-8 rounded-3xl bg-slate-500 mr-4 overflow-hidden content-center">
    //           <img
    //             src="https://media.istockphoto.com/id/1372641621/photo/portrait-of-a-businessman-on-gray-background.webp?b=1&s=170667a&w=0&k=20&c=Yyi5slaeNpq_HPcfgy1305VpJSwerPm_s7mTz_bBk6c="
    //             alt=""
    //           />
    //         </div>
    //         <div className="relative">
    //           <IoNotificationsOutline className=" text-2xl" />
    //           <span className="h-2 w-2 bg-red-500 absolute top-[2px] right-1 rounded-md"></span>
    //         </div>
    //       </div>
    //     </nav>

    //     {/* OTHER SCREENS */}

    //     <Home/>

    //   </div>
    // </div>
  );
}

export default Dashboard;
