import { appImages } from "../../data";
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import Validation from "./components/OTPValidation";

function OTP() {
  return (
    <div className=" flex flex-row">
   
       
   <div className=" bg-gradient overflow-y-scroll overflow-x-hidden h-screen justify-between w-[45%] px-10 pt-5 hidden md:flex flex-col">
        <motion.h3
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-white"
        >
          <b>BRIDGE</b>NUMERIC
        </motion.h3>
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 0 * 2).animate}
        >
          <img src={appImages.avater} alt="" className=" w-2/3 mt-1" />
        </motion.div>

        <motion.h1
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 1 * 2).animate}
          className=" text-5xl font-medium text-white"
        >
          Let's setup <br /> your Operating <br /> Agreement
        </motion.h1>

        <motion.div
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 3 * 2).animate}
          className=" mt-10 w-[90%]"
        >
          <p className="text-white mb-10">
            Remote solution for seamless organizational data collection,
            validation and management across the nation.
          </p>
        </motion.div>
      </div>

      {/* Right Hand Side */}
      <div className={`mx-5 md:mx-20 }`}>
        <img src={appImages.logo} alt="" className="  w-44 mt-20" />
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 1 * 2).animate}
        >
          <h1 className="text-3xl font-semibold mt-10">OTP 👋</h1>
          <p className="text-hint mt-1">
            One Time Password has been sent to{" "}
            <span className="text-primary">johndoe@gmail.com</span> Kindly
            populate the fields below to complete the registration
          </p>
        </motion.div>
        <Validation />
        <div className=" w-[100%]">
          <p className=" text-center mt-5 text-hint">
            Don’t receive OTP?{" "}
            <button onClick={() => {}} className="text-primary font-bold">
              Resend
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default OTP;
