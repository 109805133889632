
export const live = "https://api.bnportalng.com/api";
export const local = "http://localhost:8080/api";
export const BASEURL = live;
export const register  = `${BASEURL}/register`;
export const loginuser  = `${BASEURL}/login`;
export const verifyemail  = `${BASEURL}/verifyemail`;
export const changepassword  = `${BASEURL}/changepassword`;
export const accounts  = `${BASEURL}/accounts`;
export const businesses  = `${BASEURL}/businesses`;
export const demands  = `${BASEURL}/demands`;
export const payments  = `${BASEURL}/payment/read`;
export const updateprofile  = `${BASEURL}/account`;
export const adminupdate  = `${BASEURL}/account/update`;
export const approveAccount = `${BASEURL}/account/approve`;
export const deleteUserAccount = `${BASEURL}/account/delete`;

export const categorypricing  = `${BASEURL}/categorypricing`;
export const newBusiness = `${BASEURL}/business`;
export const deleteBusiness = `${BASEURL}/business/delete`;
export const updateBusiness = `${BASEURL}/business/update`
export const createDemand = `${BASEURL}/demand/create`
export const updateDemand = `${BASEURL}/demand/update`
export const acknowledgeDemand = `${BASEURL}/demand/acknowledge`
export const acknowledgeReminder = `${BASEURL}/demand/reminder/acknowledge`
export const deleteDemandRoute = `${BASEURL}/demand/delete`

export const paymentproof = `${BASEURL}/payment/proof`
export const paymentproofUpdate = `${BASEURL}/payment/update`
export const paymentproofDelete = `${BASEURL}/payment/delete`


