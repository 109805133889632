import React, { useState } from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import ProfileModal from '../../components/ProfileModal';
import { useSelector } from "react-redux";


const Profile = () => {

  const authentication = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(true);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  }
  return (

    <div className='pl-5 pr-6 w-[100%] flex flex-col space-y-5 overflow-hidden'>

      {/* profile section */}

      <ProfileModal onClose={toggleShowModal} isVisible={showModal} data={authentication.user} isProfile={true} />


      <div className='flex justify-end'>
        <button onClick={toggleShowModal} className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient p-4 font-light rounded-md">
          <AiOutlineEdit />
          Edit Profile
        </button>
      </div>

      <div className='w-full bg-white font-semibold rounded-sm flex items-center'>

        <div className=' text-6xl bg-background rounded-full p-5 m-5 h-40 w-40 uppercase flex justify-center items-center'>
          {authentication.user.firstname[0]} {authentication.user.lastname[0]}
        </div>
        <div className=' text-hint text-sm flex flex-col space-y-1'>
          <h3 className='text-xl text-gray-900 font-semibold capitalize'>{`${authentication.user.firstname} ${authentication.user.lastname}`}</h3>
          <p className=' text-blue-400'>{authentication.user.role === 0 ? "Staff" : "Admin"}</p>
          <p><span>Email:</span> {authentication.user.email}</p>
          <p><span>Phone:</span> {authentication.user.phone ?? "Nill"}</p>
        </div>

      </div>

      <h3 className=' font-semibold'>Statistics</h3>

      <div className='w-fullp-10 grid md:grid-cols-3 gap-2 md:gap-10'>
        <div className=' text-center bg-white py-5 rounded-md '>
          <p className=' text-green-500'>Reach</p>
          <p className=' my-5 font-bold text-5xl'>{authentication.user.busineses}</p>
          <p>Business</p>
        </div>

        <div className=' text-center bg-white py-5 rounded-md '>
          <p className=' text-green-500'>Conversion</p>
          <p className=' my-5 font-bold text-5xl'>{authentication.user.payments}</p>
          <p>Business</p>
        </div>

        <div className=' text-center bg-white py-5 rounded-md flex flex-col justify-between text-hint'>
          <div className='flex text-white justify-center space-x-3'>
            <p className='bg-green-500 rounded-full w-20 h-20 flex justify-center items-center'>{(100 - authentication.user.busineses) / 100}%</p>
            <p className='bg-blue-500 rounded-full w-20 h-20 flex justify-center items-center'>{(100 - authentication.user.invoices) / 100}%</p>
          </div>
          <div className='flex items-center space-x-2 my-5 text-sm ml-5'>
            <div className=' w-5 h-5 bg-green-500 rounded-full' ></div>
            <p>Reach within the last one month</p>
          </div>
          <div className='flex items-center space-x-2 text-sm ml-5'>
            <div className=' w-5 h-5 bg-blue-500 rounded-full' ></div>
            <p>Reach within the last one month</p>
          </div>
        </div>

      </div>

      <div className='md:hidden h-2'>

      </div>

    </div>

  )
}

export default Profile