import axios from 'axios'
import authService from './auth.service';
import { accounts } from '../../constants/routes.path';
import { loadAccounts } from '../slice/account.slice';

const fetchAccounts = async (dispatch) => {


  try {

    const response =  await axios.get(accounts, { headers: authService.authHeader()});
    const users = response.data;
    dispatch(loadAccounts(users.data));
    localStorage.setItem("accounts", JSON.stringify(users.data));

  } catch (error) {

    console.log("this error", error);

  } finally {
    // pass
  }

};


export default fetchAccounts