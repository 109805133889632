
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Load from "./pages/Load";
import Authentication from "./pages/authentication/Authentication";
import OTP from "./pages/otp/OTP";
import ProofUpload from "./pages/proofUpload/ProofUpload";
import Dashboard from "./pages/dashboard/Dashboard";
import Acknowledgment from "./pages/acknowledgement/Acknowledgement";
import PasswordReset from "./pages/authentication/PasswordReset";
import EmailVerification from "./pages/authentication/EmailVerification";

function App() {
  return (
    <Router>
        <Routes>

          <Route exact path="/" element={<Load/>} />
          <Route exact path="/authentication" element={<Authentication/>} />
          <Route exact path="/verifyemail" element={<EmailVerification/>} />
          <Route exact path="/resetpassword" element={<PasswordReset/>} />
          <Route exact path="/otpverification" element={<OTP/>} />
          <Route exact path="/uploadproof" element={<ProofUpload/>} />
          <Route exact path="/dashboard" element={<Dashboard/>} />
          <Route exact path="/acknowledgement" element={<Acknowledgment/>} />

        </Routes>
      </Router>
  );
}

export default App;
