import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebar: 0,
  subscreen: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {

    next(state, action){
      state.sidebar = action.payload;
      state.subscreen = 0;
    },

    sub(state, action){
        state.subscreen = state.subscreen === 0 ? 1 : 0;
      },

  },
});


export const {next, sub} = appSlice.actions;
export default appSlice.reducer;