import React, { useState, useEffect, useRef } from 'react'
import { RiEjectFill } from 'react-icons/ri';
import { GrFormEdit, GrPrint } from 'react-icons/gr';
import { appImages } from "../../../../data";
import { BiCheck } from "react-icons/bi";
import { formatIntDate } from '../../../../utils/date.time';
// import { AiFillCloseCircle } from "react-icons/ai";
import axios from 'axios';
import { updateDemand } from "../../../../constants/routes.path";
import authService from "../../../../app/service/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { updateDemandSlice } from '../../../../app/slice/demand.slice';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const UpdateDemand = ({ data, editItem }) => {

    const authentication = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();


    const [comment, setComment] = useState("");
    const [status, setStatus] = useState("approved");
    const [wavedprice, setWavedprice] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isUpdating, setUpdating] = useState(false);

    // const [selectedFile, setSelectedFile] = useState(null);

    const [mds, setMds] = useState("");
    const [foreignmissions, setForeignmissions] = useState("");
    const [commercial, setCommercial] = useState("");
    const [residential, setResidential] = useState("");


    const [preview, setPreview] = useState(null);


    useEffect(() => {
        function init() {

            setComment(data ? data.comment : "");
            setWavedprice(data ? data.wavedprice : null);
            setPreview(data.files.length >= 1 ? data.files[0] : null);

            setMds(data ? data.mds : "");
            setForeignmissions(data ? data.foreignmissions : "");
            setCommercial(data ? data.commercial : "");
            setResidential(data ? data.residential : "");
        }

        init();
    }, [data]);

    const toggleLoading = () => {
        setLoading((prevLoading) => !prevLoading);
    };

    const toggleUpdating = () => {
        setUpdating((prevLoading) => !prevLoading);
    };

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setSelectedFile(file);
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setPreview(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     } else {
    //         setSelectedFile(null);
    //         setPreview(null);
    //     }
    // };

    // const clearSelection = () => {
    //     setSelectedFile(null)
    //     setPreview(null)
    // }

    const handleSubmitEvent = async (e) => {
        e.preventDefault();

        toggleLoading();

        try {
            const formData = new FormData();
            formData.append('comment', comment);
            // formData.append('file', selectedFile);
            formData.append('status', status);
            formData.append('wavedprice', wavedprice);

            const url = updateDemand + "/" + data.id;

            const response = await axios.put(url, formData, { headers: authService.authHeader() });
            dispatch(updateDemandSlice(response.data.data))

            console.log('Form data submitted successfully:', response);
        } catch (error) {
            console.error('Error submitting form data:', error);
        } finally {
            toggleLoading();
        }

        console.log(preview)

    };


    const handleUpdate = async () => {

        toggleUpdating();
        const url = updateDemand + "/" + data.id;
        const payload = { mds, foreignmissions, commercial, residential, wavedprice };
        const response = await axios.put(url, payload, { headers: authService.authHeader() });
        dispatch(updateDemandSlice(response.data.data))

        toggleUpdating();

    };

    const pdfRef = useRef();

    // const Print = async () => {
    //     console.log('print');  
    //     let printContents = document.getElementById('printablediv').innerHTML;
    //     let originalContents = document.body.innerHTML;
    //     document.body.innerHTML = printContents;
    //     window.print();
    //     document.body.innerHTML = originalContents;  
    //     editItem();
    // }

    const handleDownload = () => {
        const divToDownload = document.getElementById('divToDownload');
        html2canvas(divToDownload).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
            pdf.save('demand_notice.pdf');
        });
    };

    const handleReminderDownload = () => {
        const divToDownload = document.getElementById('divToDownloadReminder');
        html2canvas(divToDownload).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
            pdf.save('demand_notice.pdf');
        });
    };

    return (

        <form method="post" onSubmit={handleSubmitEvent} className="grid md:grid-cols-3 gap-4 mt-5 mb-5 pb-5">
            <div className=' bg-white px-3 col-span-3 md:col-span-1'>
                <div className='flex justify-between items-center py-3'>
                    <p>{data.noticeId}</p>
                    <p>{data.status}</p>
                </div>
                <textarea value={comment}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                    placeholder='Enter your comment here' rows={5} className='bg-background w-full p-3'>

                </textarea>

                <input
                    value={wavedprice === "0" ? "" : wavedprice}
                    onChange={(e) => {
                        setWavedprice(e.target.value);
                    }}
                    className={authentication.role !== 2 ? "hidden" : "w-full bg-background my-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"}
                    placeholder="Rebase Price"
                    type="number"
                    name="price"
                    autoComplete="price"
                />

                {/* <div className={authentication.role === 0 ? "hidden" : "my-2 flex items-center bg-background py-2"}>

                    <label className="relative cursor-pointer ml-1">
                        <span className="text-xs w-[100%] py-2 px-8 bg-gradient text-white rounded-sm">Upload</span>
                        <input type="file" className="sr-only" onChange={handleFileChange} />
                    </label>

                    <span className="ml-2 text-sm text-gray-500">
                        {selectedFile ?
                            // selectedFile.name 
                            "Selected"
                            : "No file chosen"}
                    </span>

                </div> */}
                {/* 
                {preview && (
                    <div className="my-2">
                        <p className="text-sm font-medium text-gray-700">File Preview: <button>{<AiFillCloseCircle onClick={clearSelection} className=" text-red-500" />}</button></p>
                        <img className="mt-1 object-cover rounded-md" src={preview} alt="File Preview" />

                    </div>
                )} */}


                <div className={authentication.role === 0 ? "hidden" : 'flex justify-between space-x-4 my-2'}>
                    <button onClick={() => setStatus("approved")} type='submit' className="text-sm w-1/2 flex justify-center items-center gap-x-2 text-white bg-gradient px-2 py-3 font-light rounded-md">
                        <BiCheck />
                        {isLoading && status === 'approved' ? "Loading" : "Approve"}
                    </button>

                    <button onClick={() => setStatus("rejected")} type='submit' className="text-sm w-1/2 flex justify-center items-center gap-x-2 text-white bg-gradient-red px-2 py-3 font-light rounded-md">
                        <RiEjectFill />

                        {isLoading && status === 'rejected' ? "Loading" : "Reject"}
                    </button>
                </div>

                <button onClick={handleDownload} type='button' className={data.status !== 'approved' ? " hidden" : "text-sm w-full mb-5 flex justify-center items-center gap-x-2 text-white bg-gradient-green px-2 py-3 font-light rounded-md mt-5"}>
                    <GrPrint />
                    Print Notice
                </button>

                <button onClick={handleReminderDownload} type='button' className={data.status !== 'approved' ? " hidden" : "text-sm w-full mb-5 flex justify-center items-center gap-x-2 text-white bg-gradient px-2 py-3 font-light rounded-md"}>
                    <GrPrint />
                    Reminder Notice
                </button>


                {/* <input
                    value={wavedprice === "0" ? "" : wavedprice}
                    onChange={(e) => {
                        setWavedprice(e.target.value);
                    }}
                    className={authentication.role === 0 ? "hidden" : "w-full bg-background my-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"}
                    placeholder="Rebase Price"
                    type="number"
                    name="price"
                    autoComplete="price"
                /> */}



                <input
                    value={mds}
                    onChange={(e) => {
                        setMds(e.target.value);
                    }}
                    className={authentication.role === 0 ? "hidden" : "w-full bg-background my-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"}
                    placeholder="MDA'S"
                    type="text"
                    name="mds"
                    autoComplete="mds"
                />


                <input
                    value={foreignmissions}
                    onChange={(e) => {
                        setForeignmissions(e.target.value);
                    }}
                    className={authentication.role === 0 ? "hidden" : "w-full bg-background my-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"}
                    placeholder="Foreign Missions"
                    type="text"
                    name="foreignmissions"
                    autoComplete="foreignmissions"
                />

                <input
                    value={commercial}
                    onChange={(e) => {
                        setCommercial(e.target.value);
                    }}
                    className={authentication.role === 0 ? "hidden" : "w-full bg-background my-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"}
                    placeholder="Commercial"
                    type="text"
                    name="commercial"
                    autoComplete="commercial"
                />


                <input
                    value={residential}
                    onChange={(e) => {
                        setResidential(e.target.value);
                    }}
                    className={authentication.role === 0 ? "hidden" : "w-full bg-background my-2 focus:outline-none rounded-sm py-2 px-2 intrude font-light"}
                    placeholder="Residential"
                    type="text"
                    name="residential"
                    autoComplete="residential"
                />

                <button onClick={handleUpdate} type='button' className={authentication.role === 0 ? "hidden" : "text-sm w-full mb-5 flex justify-center items-center gap-x-2 text-white bg-gradient-green px-2 py-3 font-light rounded-md mt-5"}>
                    <GrFormEdit />
                    {isUpdating ? "Updating..." : "Update"}
                </button>

            </div>


            {previewDemandTemplate(pdfRef, data, wavedprice, mds, foreignmissions, commercial, residential)}

            {downloadDemandTemplate(pdfRef, data, wavedprice, mds, foreignmissions, commercial, residential)}

            {downloadDemandRemindeTemplate(pdfRef, data, wavedprice, mds, foreignmissions, commercial, residential)}

        </form>

    )
}

export default UpdateDemand


function downloadDemandTemplate(pdfRef, data, wavedprice, mds, foreignmissions, commercial, residential) {

    return <div id='divToDownload' className=' bg-white w-[769px] h-[1123px] bg-center col-span-3 md:col-span-2 py-4 px-5 absolute -left-[9999px]' ref={pdfRef}>

        <div className='flex flex-col relative items-center justify-center'>
            <img src={appImages.coatofarms} alt='logo' className='w-28' />
            <p className=' absolute right-0 top-5'>{data.noticeId}</p>
            <p className='text-3xl font-semibold pt-1 text-green-600'>ABUJA MUNICIPAL AREA COUNCIL</p>
            <p className='text-lg font-semibold'>P.M.B 64, GARKI - ABUJA FCT</p>
            <p className='text-[14px] text-red-500 font-semibold'>INSPECTION AND CERTIFICATION OF HABITABLE PROPERTIES OFFICE</p>
            <p className=' text-center w-[80%] md:font-semibold text-[12px]'>Number 27, ASHEIK JAMAR STREET, OFF MIKE AKHIGBE WAY, JABI ABUJA. Tel: 092923005, 08038128812, 09084888812, 08024941717</p>
            <p className='text-red-500 text-xl my-2 font-semibold uppercase'> Demand Notice</p>
        </div>

        <div className='flex flex-col'>
            <div className=' flex items-center border-2 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' '>Notice is hereby given to - THE OCCUPIER(s): <span className=' font-bold uppercase'>{data.Business.organization} </span></p>
            </div>

            <div className='grid grid-cols-4 mt-1 gap-x-2'>
                <div className=' border-2 border-gray-500 pt-2 text-left text-[12.5px]'>
                    <div className='px-1 h-6 leading-3'>ADDRESS: </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-8 leading-3'>PURPOSE/ AMOUNT: </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6 leading-3'>TYPE OF PROPERTY: </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6 leading-3'>MDA's </div>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1 h-6 leading-3'> FOREIGN MISSIONS</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6 leading-3'>COMMERCIAL</div>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1 h-6 leading-3'> RESIDENTIAL:</div>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1 h-6 leading-3'> DATE: </div>

                </div>

                <div className=' border-2 col-span-2 border-gray-500 pt-2 text-left text-[12.5px]'>
                    <div className='px-1  h-6 leading-3'>{data.Business.address}</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-8  leading-3'>INSPECTION AND CERTIFICATION OF HABITABLE PROPERTY</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1  h-6 items-center leading-3 '>{data.Business.Category.group_name}</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6'>{mds}</div>

                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1   h-6'>{foreignmissions}</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1  h-6'>{commercial}</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1 h-6'>{residential}</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1 leading-3'>{formatIntDate(data.createdAt)}</p>
                </div>

                <div className=' border-2 border-gray-500 text-left flex flex-col justify-between text-[12.5px]'>
                    <p className='px-2 text-center py-2 '>BILL INFORMATION</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1 items-center '>
                        <div className=' border-r-2 border-gray-500 w-[50%]  pb-2 pl-1 text-[10px]'>PER ANNUM: </div> <p className='pb-2'>N{wavedprice === "0" || !wavedprice ? Intl.NumberFormat().format(data.Business.price) : Intl.NumberFormat().format(wavedprice)}</p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className='border-r-2 border-gray-500 w-[50%] pb-2 pl-1 text-[10px]'>ARREARS: </p> <p>N  </p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className=' border-r-2 border-gray-500 w-[50%]  pb-2 pl-1 text-[10px]'>PENALTY 50% ON ARREARS </p> <p> = </p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-2 text-xs h-[40%]'> </p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className=' border-r-2 border-gray-500 w-[50%] pb-2 pl-1 text-[10px]'>GRAND TOTAL: </p> <p className=' font-semibold'>N{wavedprice === "0" || !wavedprice ? Intl.NumberFormat().format(data.Business.price) : Intl.NumberFormat().format(wavedprice)}</p>
                    </div>
                </div>

            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' pb-4'>In accordance with the provisions of section 7 (1) (5), and the fourth schedule of the 1999 Constitution of
                    the Federal Republic of Nigeria (as amended): Section 5 of the Local Government Act 1976; <b>Abuja
                        Municipal Area Council Bye-Law and all other relevant laws, we forward herewith, your bill for Certification of Fitness for Continuous Habitation for the year 2024 totaling =N= {data.Business.price + " "}
                        in respect of the premises you occupy as per details above .</b>
                </p>
            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' '>Pay to <b>AMAC FITNESS FOR CONTINUOUS HABITATION Account No: 0185714405</b> at any <b>UNION BANK</b> branch.<br />
                    All payments should be made in full within 7 days of receipt of this notice either via transfers certified/bank drafts) OR payment at the office address stated therein. Upon presentation of proof of payments, ensure to obtain your original certificate(s) from the same office.</p>
            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' '><span className='text-red-500 block font-semibold'>NOTE: PAYMENTS MADE OUTSIDE OF THE SPECIFIED FORMAT OR LOCATION STATED HERE WILL BE TREATED AS INVALID.</span>
                    Your prompt response is hereby solicited.</p>
            </div>

            <div className='flex space-x-10'>

                <div className='w-full text-[12.5px]'>
                    <div className=' border-2 mt-1 border-gray-500 px-1 py-1 text-left'>
                        <div className='flex space-x-1 mb-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-2 '>Name of Officer </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 capitalize text-left pb-2 '> {data.Account.firstname} {data.Account.lastname} </p>
                        </div>
                        <div className='flex space-x-1 mb-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2 text-left '>Date of Dispatch </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2  text-left'>{formatIntDate(data.updatedAt)} </p>
                        </div>
                        <div className='flex space-x-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2 text-left'>Means of Dispatch </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2 text-left'>  </p>
                        </div>
                    </div>
                </div>


                <div className='w-full'>
                    <div className=' border-2 mt-1 border-gray-500 px-1 py-1 text-left  text-[12.5px]'>
                        <p className='border-2 border-gray-500 text-center px-1 mb-1 pb-2'>ACKNOWLEDGMENT</p>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>NAME: </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>
                                {/* {data.name} */}
                            </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left pb-1'>DATE: </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left pb-1'>
                                {/* {formatIntDate(data.acknowlededAt)} */}
                            </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left pb-1'>SIGNATURE </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>  </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>PHONENUMBER </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>
                                {/* {data.phone}  */}
                            </p>
                        </div>
                        <div className='flex space-x-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>DESIGNATION </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>
                                {/* {data.name} */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={appImages.stamp} alt='' className=' border-2 w-64 h-28 bottom-8 left-[0%] rounded-md  absolute' />


            <img src={appImages.bottom} alt='' className=' border-2  w-16 h-16 bottom-4 md:bottom-6 md:left-[39%] rounded-md left-[38%] absolute' />

            <div className='flex justify-between text-[16px]'>
                <p className=' text-hint font-semibold'>Team Lead/Operational Head<br /> Habitation Office</p>
                <p className=' text-red-500 font-semibold'>DEPARTMENT OF ENVIRONMENTAL SERVICE</p>
            </div>


        </div>
    </div>;
}

function downloadDemandRemindeTemplate(pdfRef, data, wavedprice, mds, foreignmissions, commercial, residential) {

    return <div id='divToDownloadReminder' className=' bg-white w-[769px] h-[1123px] bg-center col-span-3 md:col-span-2 py-4 px-5 absolute -left-[9999px]' ref={pdfRef}>

        <div className='flex flex-col relative items-center justify-center'>
            <img src={appImages.coatofarms} alt='logo' className='w-28' />
            <p className=' absolute right-0 top-5'>{data.noticeId}</p>
            <p className='text-3xl font-semibold pt-1 text-green-600'>ABUJA MUNICIPAL AREA COUNCIL</p>
            <p className='text-lg font-semibold'>P.M.B 64, GARKI - ABUJA FCT</p>
            <p className='text-[14px] text-red-500 font-semibold'>INSPECTION AND CERTIFICATION OF HABITABLE PROPERTIES OFFICE</p>
            <p className=' text-center w-[80%] md:font-semibold text-[12px]'>Number 27, ASHEIK JAMAR STREET, OFF MIKE AKHIGBE WAY, JABI ABUJA. Tel: 092923005, 08038128812, 09084888812, 08024941717</p>
            <p className='text-red-500 text-xl my-2 font-semibold uppercase'>Reminder Notice</p>
        </div>

        <div className='flex flex-col'>
            <div className=' flex items-center border-2 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' '>Notice is hereby given to - THE OCCUPIER(s): <span className=' font-bold uppercase'>{data.Business.organization} </span></p>
            </div>

            <div className='grid grid-cols-4 mt-1 gap-x-2'>
                <div className=' border-2 border-gray-500 pt-2 text-left text-[12.5px]'>
                    <div className='px-1 h-6 leading-3'>ADDRESS: </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-8 leading-3'>PURPOSE/ AMOUNT: </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6 leading-3'>TYPE OF PROPERTY: </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6 leading-3'>MDA's </div>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1 h-6 leading-3'> FOREIGN MISSIONS</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6 leading-3'>COMMERCIAL</div>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1 h-6 leading-3'> RESIDENTIAL:</div>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1 h-6 leading-3'> DATE: </div>

                </div>

                <div className=' border-2 col-span-2 border-gray-500 pt-2 text-left text-[12.5px]'>
                    <div className='px-1  h-6 leading-3'>{data.Business.address}</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-8  leading-3'>INSPECTION AND CERTIFICATION OF HABITABLE PROPERTY</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1  h-6 items-center leading-3 '>{data.Business.Category.group_name}</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='px-1 h-6'>{mds}</div>

                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <div className='px-1   h-6'>{foreignmissions}</div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1  h-6'>{commercial}</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1 h-6'>{residential}</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1 leading-3'>{formatIntDate(data.createdAt)}</p>
                </div>

                <div className=' border-2 border-gray-500 text-left flex flex-col justify-between text-[12.5px]'>
                    <p className='px-2 text-center py-2 '>BILL INFORMATION</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1 items-center '>
                        <div className=' border-r-2 border-gray-500 w-[50%] pb-2 text-[10px] pl-1'>PER ANNUM: </div> <p className='pb-2'>N{wavedprice === "0" || !wavedprice ? Intl.NumberFormat().format(data.Business.price) : Intl.NumberFormat().format(wavedprice)}</p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className='border-r-2 border-gray-500 w-[50%] pb-2 text-[10px] pl-1'>ARREARS: </p> <p>N  </p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className=' border-r-2 border-gray-500 w-[50%] text-[10px] pb-2 pl-1'>PENALTY 50% ON ARREARS </p> <p> = </p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-2 text-xs h-[40%]'> </p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className=' border-r-2 border-gray-500 w-[50%] pb-2 text-[10px] pl-1'>GRAND TOTAL: </p> <p className=' font-semibold'>N{wavedprice === "0" || !wavedprice ? Intl.NumberFormat().format(data.Business.price) : Intl.NumberFormat().format(wavedprice)}</p>
                    </div>
                </div>

            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' pb-4'>In accordance with the provisions of section 7 (1) (5), and the fourth schedule of the 1999 Constitution of
                    the Federal Republic of Nigeria (as amended): Section 5 of the Local Government Act 1976; <b>Abuja
                        Municipal Area Council Bye-Law and all other relevant laws, we forward herewith, your bill for Certification of Fitness for Continuous Habitation for the year 2024 totaling =N= {data.Business.price + " "}
                        in respect of the premises you occupy as per details above .</b>
                </p>
            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' '>Pay to <b>AMAC FITNESS FOR CONTINUOUS HABITATION Account No: 0185714405</b> at any <b>UNION BANK</b> branch.<br />
                    All payments should be made in full within 7 days of receipt of this notice either via transfers certified/bank drafts) OR payment at the office address stated therein. Upon presentation of proof of payments, ensure to obtain your original certificate(s) from the same office.</p>
            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[12.5px]'>
                <p className=' '><span className='text-red-500 block font-semibold'>NOTE: PAYMENTS MADE OUTSIDE OF THE SPECIFIED FORMAT OR LOCATION STATED HERE WILL BE TREATED AS INVALID.</span>
                    Your prompt response is hereby solicited.</p>
            </div>

            <div className='flex space-x-10'>

                <div className='w-full text-[12.5px]'>
                    <div className=' border-2 mt-1 border-gray-500 px-1 py-1 text-left'>
                        <div className='flex space-x-1 mb-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-2 '>Name of Officer </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 capitalize text-left pb-2 '> {data.Account.firstname} {data.Account.lastname} </p>
                        </div>
                        <div className='flex space-x-1 mb-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2 text-left '>Date of Dispatch </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2  text-left'>{formatIntDate(Date())} </p>
                        </div>
                        <div className='flex space-x-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2 text-left'>Means of Dispatch </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 pb-2 text-left'>  </p>
                        </div>
                    </div>
                </div>


                <div className='w-full'>
                    <div className=' border-2 mt-1 border-gray-500 px-1 py-1 text-left  text-[12.5px]'>
                        <p className='border-2 border-gray-500 text-center px-1 mb-1 pb-2'>ACKNOWLEDGMENT</p>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>NAME: </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>
                                {/* {data.name} */}
                            </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left pb-1'>DATE: </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left pb-1'>
                                {/* {!data.acknowlededAt ? "" : formatIntDate(data.acknowlededAt)} */}
                            </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left pb-1'>SIGNATURE </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>  </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>PHONENUMBER </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>
                                {/* {data.phone} */}
                            </p>
                        </div>
                        <div className='flex space-x-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>DESIGNATION </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left pb-1'>
                                {/* {data.name} */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={appImages.stamp} alt='' className=' border-2 w-64 h-28 bottom-8 left-[0%] rounded-md  absolute' />


            <img src={appImages.bottom} alt='' className=' border-2  w-16 h-16 bottom-4 md:bottom-6 md:left-[39%] rounded-md left-[38%] absolute' />

            <div className='flex justify-between text-[16px]'>
                <p className=' text-hint font-semibold'>Team Lead/Operational Head<br /> Habitation Office</p>
                <p className=' text-red-500 font-semibold'>DEPARTMENT OF ENVIRONMENTAL SERVICE</p>
            </div>


        </div>
    </div>;
}

function previewDemandTemplate(pdfRef, data, wavedprice, mds, foreignmissions, commercial, residential) {
    return <div id='printableMobilediv' className='bg-white bg-center col-span-3 md:col-span-2 py-4 px-5 relative' ref={pdfRef}>

        <div className='flex flex-col relative items-center justify-center'>
            <img src={appImages.coatofarms} alt='logo' className='w-28' />
            <p className=' absolute right-0 top-5'>{data.noticeId}</p>
            <p className='text-md md:text-3xl font-semibold pt-1 text-green-600'>ABUJA MUNICIPAL AREA COUNCIL</p>
            <p className=' text-xs md:text-lg font-semibold'>P.M.B 64, GARKI - ABUJA FCT</p>
            <p className=' text-[7.5px] md:text-[14px] text-red-500 font-semibold'>INSPECTION AND CERTIFICATION OF HABITABLE PROPERTIES OFFICE</p>
            <p className=' text-center w-[100%] md:w-[90%] md:font-semibold text-[7.5px] md:text-[12px]'>Number 27, ASHEIK JAMAR STREET, OFF MIKE AKHIGBE WAY, JABI ABUJA. Tel: 092923005, 08038128812, 09084888812, 08024941717</p>
            <p className='text-red-500 text-md md:text-xl my-2 font-semibold uppercase'>Demand Notice</p>
        </div>

        <div className='flex flex-col'>
            <div className=' border-2 border-gray-500 px-2 py-2 text-left w-full text-[6.5px] md:text-[12.5px]'>
                <p className=' '>Notice is hereby given to - THE OCCUPIER(s): <span className=' font-bold uppercase'>{data.Business.organization} </span></p>
            </div>

            <div className='grid grid-cols-4 mt-1 gap-x-2'>
                <div className=' border-2 border-gray-500 pt-2 text-left text-[5.5px] md:text-[12.5px]'>
                    <p className='px-1  h-6 '>ADDRESS: </p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1 h-6 '>PURPOSE/ AMOUNT: </p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1 h-6 '>TYPE OF PROPERTY: </p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1 h-4 '>MDA's </p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1 h-4 flex items-center'> FOREIGN MISSIONS</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1 h-4'>COMMERCIAL</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1 h-4'> RESIDENTIAL:</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1'> DATE: </p>

                </div>

                <div className=' border-2 col-span-2 border-gray-500 pt-2 text-left text-[5.5px] md:text-[12.5px]'>
                    <p className='px-1  h-6 '>{data.Business.address}</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1 h-6  leading-3'>INSPECTION AND CERTIFICATION OF HABITABLE PROPERTY</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1  h-6 flex items-center '>{data.Business.Category.group_name}</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className=' h-[16px] px-1'>{mds}</p>

                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1  h-4'>{foreignmissions}</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-1  h-[16px]'>{commercial}</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-1  h-[16px]'>{residential}</p>
                    <hr className=' border-[1.5px] my-1 border-gray-500' />
                    <p className='px-2'>{formatIntDate(data.createdAt)}</p>
                </div>

                <div className=' border-2 border-gray-500 text-left flex flex-col justify-between text-[5.5px] md:text-[12.5px]'>
                    <p className='px-2 text-center py-2 '>BILL INFORMATION</p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <div className=' border-r-2 border-gray-500 text-[11px] w-[50%] pl-1 m-0'>PER ANNUM: </div> <p>N{wavedprice === "0" || !wavedprice ? Intl.NumberFormat().format(data.Business.price) : Intl.NumberFormat().format(wavedprice)}</p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className='border-r-2 border-gray-500 w-[50%] text-[11px] px-1'>ARREARS: </p> <p>N </p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className=' border-r-2 border-gray-500 text-[11px] w-[50%] px-1'>PENALTY 50% ON ARREARS </p> <p> = </p>
                    </div>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <p className='px-2 text-xs h-[40%]'> </p>
                    <hr className=' border-[1.5px] border-gray-500' />
                    <div className='flex space-x-1'>
                        <p className=' border-r-2 border-gray-500 text-[12px] w-[70%] py-1'>GRAND TOTAL: </p> <p className=' font-semibold w-[70%] py-1'>N{wavedprice === "0" || !wavedprice ? Intl.NumberFormat().format(data.Business.price) : Intl.NumberFormat().format(wavedprice)}</p>
                    </div>
                </div>

            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[7.5px] md:text-[12.5px]'>
                <p className=' pb-4'>In accordance with the provisions of section 7 (1) (5), and the fourth schedule of the 1999 Constitution of
                    the Federal Republic of Nigeria (as amended): Section 5 of the Local Government Act 1976; <b>Abuja
                        Municipal Area Council Bye-Law and all other relevant laws, we forward herewith, your bill for Certification of Fitness for Continuous Habitation for the year 2024 totaling =N= {data.Business.price + " "}
                        in respect of the premises you occupy as per details above .</b>
                </p>
            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-[7.5px] md:text-[12.5px]'>
                <p className=' '>Pay to <b>AMAC FITNESS FOR CONTINUOUS HABITATION Account No: 0185714405</b> at any <b>UNION BANK</b> branch.<br />
                    All payments should be made in full within 7 days of receipt of this notice either via transfers certified/bank drafts) OR payment at the office address stated therein. Upon presentation of proof of payments, ensure to obtain your original certificate(s) from the same office.</p>
            </div>

            <div className=' border-2 mt-1 border-gray-500 px-2 py-2 text-left w-full text-xs text-[7.5px] md:text-[12.5px]'>
                <p className=' '><span className='text-red-500 block font-semibold'>NOTE: PAYMENTS MADE OUTSIDE OF THE SPECIFIED FORMAT OR LOCATION STATED HERE WILL BE TREATED AS INVALID.</span>
                    Your prompt response is hereby solicited.</p>
            </div>

            <div className='flex space-x-10'>

                <div className='w-full text-[5.5px] md:text-[12.5px]'>
                    <div className=' border-2 mt-1 border-gray-500 px-1 py-1 text-left'>
                        <div className='flex space-x-1 mb-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left md:py-1 '>Name of Officer </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 capitalize text-left md:py-1 '> {data.Account.firstname} {data.Account.lastname} </p>
                        </div>
                        <div className='flex space-x-1 mb-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 md:py-1 text-left '>Date of Dispatch </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 md:py-1  text-left'>{formatIntDate(data.updatedAt)} </p>
                        </div>
                        <div className='flex space-x-1 leading-3'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 md:py-1 text-left'>Means of Dispatch </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 md:py-1 text-left'>  </p>
                        </div>
                    </div>
                </div>


                <div className='w-full'>
                    <div className=' border-2 mt-1 border-gray-500 px-1 py-1 text-left  text-[5.5px] md:text-[12.5px]'>
                        <p className='border-2 border-gray-500 text-center px-1 mb-1'>ACKNOWLEDGMENT</p>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left'>NAME: </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left'>
                                {/* {data.name} */}
                            </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left'>DATE: </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left'>
                                {/* {!data.acknowlededAt ? "" : formatIntDate(data.acknowlededAt)} */}
                            </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1  text-left'>SIGNATURE </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left'>  </p>
                        </div>
                        <div className='flex space-x-1 mb-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left'>PHONENUMBER </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left'>
                                {/* {data.phone} */}
                            </p>
                        </div>
                        <div className='flex space-x-1'>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left'>DESIGNATION </p>
                            <p className='border-2 border-gray-500 w-1/2 px-1 text-left'>
                                {/* {data.name} */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={appImages.stamp} alt='' className='w-28 h-12 md:w-64 md:h-28 bottom-6 md:bottom-8  md:left-[0%] rounded-md left-[3%] absolute' />


            <img src={appImages.bottom} alt='' className=' w-8 h-8 md:w-16 md:h-16 bottom-4 md:bottom-6 md:left-[39%] rounded-md left-[38%] absolute' />

            <div className='flex justify-between text-[7.5px] md:text-[14px]'>
                <p className=' text-hint font-semibold'>Team Lead/Operational Head<br /> Habitation Office</p>
                <p className=' text-red-500 font-semibold'>DEPARTMENT OF ENVIRONMENTAL SERVICE</p>
            </div>


        </div>
    </div>;
}


