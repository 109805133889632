
import React, { useState } from "react";
import { RiCloseFill } from 'react-icons/ri'
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import { formatStringDate } from '../../utils/date.time';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import {updatePayment} from "../../app/slice/payment.slice";
import { paymentproofUpdate } from '../../constants/routes.path';
import authService from "../../app/service/auth.service";
import { useDispatch, useSelector } from "react-redux";


const PaymentModal = ({ onClose, isVisible, data }) => {

    const authentication = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();

    const [verify, setVerify] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);



    const handleUpdateProof= async(e)=>{
        e.preventDefault();

        if( authentication.role !== 2){
            toast.error("You are not authorized");
            return;
        }

        const body = {
            "verified": verify ,
            "status": verify ? "approved":"unapproved"
          };
       let url
       if(data){
       url =  paymentproofUpdate + "/" +  data.id;
       }else{
        url = paymentproofUpdate 
       }

        const response = await axios.put(url, body, {headers: authService.authHeader()});
        // const message = response.data.message;
        const updatedData = response.data.data;
        console.log("this is the information", updatedData)
        dispatch(updatePayment(updatedData));
        onClose();
        // toast.success(verify ? "Sucessfully Approved": "Sucessfully Unapproved");
    }


    return (

        <div className={isVisible ? 'hidden' : 'fixed z-50 w-[100%] md:w-[94%] h-[85%] bg-background bg-opacity-80  flex justify-center items-center'}>
           
            <ToastContainer />

            <form method='post' onSubmit={handleUpdateProof}  className={'w-[90%] mr-10 md:mr-0 md:w-[40%]  rounded-md md:left-[25%]  bg-white absolute'}>
                <div className=' flex items-center justify-between px-5 py-3'>
                    <p className=' text-hint font-semibold'>Payment Proof</p>
                    <button onClick={onClose} type="button"><RiCloseFill className=' text-red-500' /></button>
                </div>
                <hr className=' my-2' />

                <div className='flex space-x-5 mx-5'>

                    <motion.div
                        initial={slideIn("up", null).initial}
                        whileInView={slideIn("up", 0 * 2).animate}
                        className=" w-full h-48 bg-background mt-5"
                        onClick={() => setShowImagePreview(true)} // Open image preview modal on click

                    >
                    <img className=" h-48" src={data ? data.file : ""} alt={data ? data.file : ""}/>

                    </motion.div>
                </div>


                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5 uppercase  py-2 px-2"
                >
                    <p className="text-hint">{ !data ? "" :  data.Business ? data.Business.organization :""}</p>

                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5 py-2 bg-background  px-2"
                >
                    <p className="text-hint">Payment ID: <span className=' font-semibold'>{data ? data.paymentId : ""}</span></p>

                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5  mt-3  py-2 px-2"
                >
                    <p className="text-hint">Demand ID: { !data ? "" :  data.Demand ? data.Demand.noticeId :""}</p>

                </motion.div>


                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5 bg-background py-2 px-2 mb-5"
                >
                    <p className="text-hint">Date: {data ? formatStringDate(data.createdAt) : ""}</p>

                </motion.div>




                <div className={authentication.role === 2 ? 'flex justify-end mx-5 mt-5 mb-3 space-x-4': "hidden"}>
                    <button onClick={()=>setVerify(true)} className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient-green px-5 py-2 font-light rounded-md">

                        Approve
                    </button>
                    <button onClick={()=>setVerify(false)} className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient-red px-5 py-2 font-light rounded-md">

                        Decline
                    </button>
                </div>

            </form>

                 {/* Image preview modal */}
                 {showImagePreview && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="absolute inset-0 bg-black opacity-80" onClick={() => setShowImagePreview(false)}></div>
                    <div className="relative max-w-full max-h-full">
                        <img src={data ? data.file : ""} alt={data ? data.file : ""} className="max-w-full max-h-full" />
                    </div>
                </div>
            )}

        </div>


    )
}

export default PaymentModal