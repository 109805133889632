import React, { useState } from 'react'
import { MdMoreVert } from "react-icons/md";
import SimplePieChart from "../../components/SimpleChart";
import { BiHomeAlt } from "react-icons/bi";
import { RiCashLine } from "react-icons/ri";
import {
    GrDocumentNotes,
    GrDocumentTransfer,
  } from "react-icons/gr";

import { useSelector } from 'react-redux';
import {formatStringDate } from '../../../utils/date.time'
import {calculatePercentageChange} from '../../../utils/amount.percentagechange';
import OrganizationModal from '../../components/OrganizationModal';
import { ToastContainer } from 'react-toastify';


const Home = () => {

  const accountState = useSelector((state)=> state.account);
  const businessState = useSelector((state)=> state.business);
  const demandState = useSelector((state)=> state.demand);
  const paymentState = useSelector((state)=> state.payment);


  const accountInstances = accountState.users;
  const businessIntances = businessState.businesses;
  const demandInstances = demandState.demands;
  const paymentInstance = paymentState.payments

  const users = accountInstances.length <= 5 ? accountInstances : accountInstances.slice(0,5)

  const payment = paymentInstance.length <= 5 ? paymentInstance: paymentInstance.slice(0,5)

  const [showModal, setShowModal] = useState(true);

  const toggleShowModal = () => {
     setShowModal(!showModal);
}


  return (
    <div className="px-5 w-[100%]">
    
    <OrganizationModal onClose={()=> toggleShowModal(null)} data={null} isVisible={showModal} isCreate={true} />
    <ToastContainer/>


    <section className="grid md:grid-cols-2 gap-4">
      {/* Left welcome statistic section */}
      <div className="flex flex-col text-hint justify-between">
        <div className=" mb-2 bg-banner w-[100%] p-4 rounded-md text-white">
          {/* <img src={appImages.banner} alt="" /> */}
          <p className=" text-2xl font-bold">Welcome Back!</p>
          <p className=" text-sm font-light mt-2">
            Enjoy convenient solution for administrative <br />
            management
          </p>

          <button onClick={toggleShowModal} className="text-sm bg-gradient-red px-2 py-2 float-right font-light rounded-md">
            {" "}
            New Organization
          </button>
        </div>

        <div className=" grid grid-cols-3 gap-2">
          <div className=" bg-white p-2 rounded-md">
            <div className=" flex items-center justify-between mb-4">
              <p className=" text-xs">Total Business</p>
              <MdMoreVert />
            </div>
            <div className=" flex items-center space-x-2">
              <span className="p-3 rounded-md bg-background">
                <BiHomeAlt />
              </span>
              <p className=" text-2xl font-bold text-black">{businessIntances.length <= 9 ? `0${businessIntances.length}`: businessIntances.length}</p>
            </div>
          </div>

          <div className=" bg-white p-2 rounded-md">
            <div className=" flex items-center justify-between mb-4">
              <p className=" text-xs">Demand Notice</p>
              <MdMoreVert />
            </div>
            <div className=" flex items-center space-x-2">
              <span className="p-3 rounded-md bg-background">
                <GrDocumentNotes />
              </span>
              <p className=" text-2xl font-bold text-black">{demandInstances.length <= 9 ? `0${demandInstances.length}`: demandInstances.length}</p>
            </div>
          </div>

          <div className=" bg-white p-2 rounded-md flex flex-col justify-between">
            <div className=" flex items-center justify-between mb-4">
              <p className=" text-xs">Payments</p>
              <MdMoreVert />
            </div>
            <div className=" flex items-center space-x-2">
              <span className="p-3 rounded-md bg-background">
                <RiCashLine />
              </span>
              <p className=" text-2xl font-bold text-black">{paymentInstance.length <= 9 ? `0${paymentInstance.length}`: paymentInstance.length}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Right welcome statistic section */}
      <div className=" bg-white rounded-md px-5 pt-2">
        <div className=" flex justify-between items-center">
          <h3 className="text-xl font-semibold">
            Recent Activity Matrix
          </h3>
          {/* <div className="flex items-center border-[1px] rounded-md space-x-1 border-hint px-3 py-1">
            <p className=" text-hint text-sm">Refresh</p>
            <BiRefresh className=' font-medium text-xl text-green-500
            ' />
          </div> */}
        </div>

        <div className="flex justify-between">
          <div className=" text-hint">
            <div className=" flex items-center space-x-1 text-xs mt-5 mb-2">
              <p className=" bg-green-500 text-white px-2 py-1 rounded-xl">
                N30.0M
              </p>
              <p>30 days</p>
            </div>
            <p className=" text-2xl font-bold text-black">{calculatePercentageChange(paymentInstance)}%</p>
            <p className=" text-xs">{calculatePercentageChange(paymentInstance) >= 1 ? "Better than last month":"Less than last month"}</p>

            <div className=" flex items-center space-x-1 text-xs mt-5 mb-3">
              <div className=" bg-green-500 px-2 py-2 rounded-sm"></div>
              <p>Business Registration</p>
            </div>

            <div className=" flex items-center space-x-1 text-xs mb-5">
              <div className=" bg-orange-500 px-2 py-2 rounded-sm"></div>
              <p>Revenue Generated</p>
            </div>
          </div>

          <div className=" h-44 w-44  mx-auto my-auto">
            <SimplePieChart />
          </div>
        </div>
      </div>
    </section>

    <section className="grid md:grid-cols-3 gap-4 mt-5 mb-2">
      <div className="rounded-md  pt-3 bg-white pb-2">
        <div className=" mx-2 flex items-center justify-between mb-2">
          <h3 className=" text-hint">Recent Payments</h3>
          <MdMoreVert />
        </div>
        <hr className=" border-grey" />

        {payment.map((element, index) => (
          <div key={index} className="h-10">
            <div className="flex justify-start items-center mx-2 mt-1">
              <div className=" text-purple-600 p-2 rounded-sm bg-purple-50 mr-2">
                <GrDocumentTransfer />
              </div>
              <div className="w-full">
                <h3 className=" text-sm text-hint">{element.Business ? element.Business.organization  : ""}</h3>
                <div className="flex space-x-3 items-center justify-between w-[100%]">
                  <p className=" text-xs text-gray-400 font-light">
                    {formatStringDate(element.createdAt)}
                  </p>
                  <p className=" text-xs font-semibold text-green-500">
                    N {element.amount}
                  </p>
                </div>
              </div>
            </div>
            <hr className=" border-grey mt-1" />
          </div>
        ))}
        <hr className="border-grey" />
      </div>

      <div className="rounded-md  pt-3 bg-white pb-2 md:col-span-2">
        <div className=" mx-2 flex items-center justify-between mb-2">
          <h3 className=" text-hint">Recent Users</h3>
          <MdMoreVert />
        </div>
        <hr className=" border-grey" />

        <table className="min-w-full">
          <thead className=" text-left h-11">
            <tr className="text-sm h-11 text-hint">
              <th className=" py-1 px-2">S/N</th>
              <th className=" py-1 px-2">Name</th>
              <th className=" py-1 px-2 hidden md:block">Email</th>
              <th className=" py-1 px-2">Phone</th>
              <th className=" py-1 px-2 hidden md:block">Date</th>
            </tr>
          </thead>
          {/* 
          <tr className="text-sm h-11 text-hint">
            <td className=" py-1 px-2 ">S/N</td>
            <td className=" py-1 px-2">Name</td>
            <td className=" py-1 px-2">Email</td>
            <td className=" py-1 px-2">Date</td>
            <td className=" py-1 px-2">Action</td>
          </tr> */}

          <tbody>
            {users.map((element, index) => (
              <tr
                className={`text-sm h-11 ${
                  index % 2 === 0 ? "bg-gray-100" : ""
                } text-hint`}
                key={index}
              >
                <td className=" py-1 px-2 ">{index + 1}</td>
                <td className=" py-1 px-2">{element.firstname}</td>
                <td className=" py-1 px-2 hidden md:block">{element.email}</td>
                <td className=" py-1 px-2">{element.phone}</td>
                <td className=" py-1 px-2 hidden md:block">{formatStringDate(element.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
    
  </div>
  )
}

export default Home