import { useEffect } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Load = () => {
  let navigate = useNavigate();

  useEffect(() => {


    const checkAuth = async () => {
      const token = localStorage.getItem("token");

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(5000);

      if (token === null) {
        navigate("/authentication");
      } else {
        navigate("/dashboard");
      }
    };

    checkAuth();


  }, [navigate]);

  return (
    <div className=" flex justify-center items-center h-screen">


      {/* <div className=" relative p-60">
        <img src="https://www.powerreviews.com/wp-content/uploads/2021/05/Artboard-5.png" alt="" />

        <h1 className=" mt-10 text-center text-2xl">Site is currently on Review</h1>
        <h1 className=" mt-10 text-center">Kndly checkback</h1>

      </div> */}





      <InfinitySpin
        visible={true}
        width="200"
        color="#4fa94d"
        ariaLabel="infinity-spin-loading"
      />


    </div>
  );
};

export default Load;
