import { useState, useEffect } from "react";
import { appImages } from "../../data";
import LoginSection from "./components/LoginSection";
import SignupSection from "./components/SignupSection";
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiCheckCircle } from "react-icons/bi";
import { GrUpload } from "react-icons/gr";

function Authentication() {


  let navigate = useNavigate();

  const authentication  = useSelector((state) => state.auth);

  useEffect(() => {
    if (authentication.user) {
      navigate('/dashboard')
    }

  }, [navigate, authentication.user]);


  const uploadproof=()=>{
    navigate('/uploadproof');
  }

  const acknowlege = ()=>{
     navigate('/acknowledgement');
  }
    
  const [isLogin, setIsLogin] = useState(true);

  const toggleAuth = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className=" flex flex-row">
    
    <div className=" bg-gradient overflow-y-scroll overflow-x-hidden h-screen justify-between w-[45%] px-10 pt-5 hidden md:flex flex-col">
        <motion.h3
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-white"
        >
          <b>BRIDGE</b>NUMERIC
        </motion.h3>
        <motion.div
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 0 * 2).animate}
        >
          <img src={appImages.avater} alt="" className=" w-2/3 mt-1" />
        </motion.div>

        <motion.h1
          initial={slideIn("up", null).initial}
          whileInView={slideIn("up", 1 * 2).animate}
          className=" text-5xl font-medium text-white"
        >
          Let's setup <br /> your Operating <br /> Agreement
        </motion.h1>

        <motion.div
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 3 * 2).animate}
          className=" mt-10 w-[90%]"
        >
          <p className="text-white mb-10">
            Remote solution for seamless organizational data collection,
            validation and management across the nation.
          </p>
        </motion.div>

       
      </div>

      {/* Right Hand Side */}
      <div className={`mx-5 md:mx-20 flex flex-col justify-start mt-24 md:mt-0 md:h-screen`}>

        <div className=" flex items-center justify-start md:justify-end md:mt-5 space-x-3">
        <motion.button
          onClick={uploadproof}
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-white mb-10 flex items-center bg-primary justify-center gap-x-3 border-2 border-primary py-2 px-2 rounded-md"
        >
        <GrUpload/>
         Proof of payment
        </motion.button>

        <motion.button
          onClick={acknowlege}
          initial={slideIn("left", null).initial}
          whileInView={slideIn("left", 0 * 2).animate}
          className=" text-primary mb-10 flex items-center justify-center gap-x-3 border-2 border-primary py-2 px-2 rounded-md"
        >
        <BiCheckCircle/>
          Acknowledgment
        </motion.button>
        </div>
        <img src={appImages.logo} alt="" className="  w-44 mt-1" />
        <motion.div
         initial={slideIn("up", null).initial}
         whileInView={slideIn("up", 1 * 2).animate}
        >
          <h1 className="text-3xl font-semibold mt-10">
            {isLogin ? "Sign In" : "Sign Up"} 👋
          </h1>
          <p className="text-hint mt-1">
            Welcome to our community, please put your login credentials below to
            start using the website.
          </p>
        </motion.div>

        {isLogin ? <LoginSection /> : <SignupSection />}

        {isLogin ? (
          <div className=" w-[100%]">
            <p className=" text-center mt-5 text-hint">
              Don’t have an account?{" "}
              <button onClick={toggleAuth} className="text-primary font-bold">
                Create an Account
              </button>
            </p>
          </div>
        ) : (
          <div className="my-5 w-[100%]">
            <p className=" text-center text-hint">
              Already have an account ?{" "}
              <button onClick={toggleAuth} className="text-primary font-bold">
                login here
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Authentication;
