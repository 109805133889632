import React, { useState, useEffect } from 'react'
import { MdArrowBack, MdArrowForward, MdDelete, MdEditRoad, MdPrint, MdVisibility } from "react-icons/md";
import { BiCheckCircle, BiSearch } from "react-icons/bi";
import UpdateDemand from './UpdateDemand';
import { useSelector, useDispatch } from "react-redux";
import { formatIntDate } from '../../../../utils/date.time';
import { removeDemand } from '../../../../app/slice/demand.slice';
import { deleteDemandRoute } from '../../../../constants/routes.path';
import axios from 'axios';
import authService from '../../../../app/service/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import DemandModal from "../../../components/DemandModal"


const Demand = () => {

    const demandState = useSelector((state) => state.demand);
    const authentication = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();

    useEffect(() => {
        setDemands(demandState.demands);
    }, [demandState.demands]);


    const [showModal, setShowModal] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10)

    const [edit, setEdit] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [damands, setDemands] = useState([...demandState.demands])


    const editItem = (index) => {
        setSelectedIndex(index);
        setEdit(!edit);
    }

    const toggleShowModal = (index) => {
        setSelectedIndex(index);
        setShowModal(!showModal);
    }

    const deleteDemand = async (index) => {

        if (authentication.role !== 2) {
            toast.error("You are not authorized");
            return;
        }

        const url = deleteDemandRoute + `/${index}`;

        const response = await axios.delete(url, { headers: authService.authHeader() });
        const message = response.data.message;
        dispatch(removeDemand({ id: index }));
        toast.success(message);
    }


    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const paginatedBusinesses = damands.slice(indexOfFirstItem, indexOfLastItem);

    const search = (query) => {
        const filteredDemands = demandState.demands.filter((business) =>
            business.Business.organization.toLowerCase().includes(query.toLowerCase()) || business.noticeId.toLowerCase().includes(query.toLowerCase()) || business.Account.firstname.toLowerCase().includes(query.toLowerCase()) || business.Account.lastname.toLowerCase().includes(query.toLowerCase()) 
        );
        setDemands(filteredDemands);
    };

    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
        filterMine(isChecked);
    };

    const filterMine = (mine) => {
        if (!mine) {
            const filteredDemands = demandState.demands.filter((business) =>
                business.Account.firstname.toLowerCase().includes(authentication.firstname) && business.Account.lastname.toLowerCase().includes(authentication.lastname)
            );
            console.log("filteredDemands", filteredDemands);
            setDemands(filteredDemands);
        } else {
            const filtered = demandState.demands;
            console.log("filtered", filtered);
            setDemands(filtered);
        }

    };


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='pl-5 pr-6 w-[100%] flex flex-col space-y-5 overflow-hidden'>

            {/* profile section */}

            {/* <OrganizationModal onClose={toggleShowModal} isVisible={showModal} /> */}

            <DemandModal onClose={toggleShowModal} isVisible={showModal} data={paginatedBusinesses[selectedIndex]} />


            <ToastContainer />


            <div className={edit ? 'hidden' : 'flex justify-end'}>
                {/* <button onClick={toggleShowModal} className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient px-2 py-2 font-light rounded-md">
                    <IoAddSharp />
                    New Business
                </button> */}
            </div>

            <div className={edit ? 'flex justify-start' : 'hidden'}>
                <button onClick={editItem} className="flex justify-center items-center">
                    <MdArrowBack className='mr-4' />
                    Back
                </button>
            </div>



            <section className={edit ? "hidden" : "grid md:grid-cols-2 gap-4 mt-5 mb-2"}>

                <div className="rounded-md  pt-3 bg-white pb-2 md:col-span-2">
                {/* FILTER */}
                <div className='flex items-center space-x-4 justify-end mb-5 w-full px-5'>
                    <p>Filter By Me</p>
                    <label className="toggle-container">
                        <input
                            type="checkbox"
                            className="toggle-checkbox"
                            checked={isChecked}
                            onChange={toggleCheckbox}
                        />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
                
                    <div className=" mx-2 flex flex-col md:flex-row items-end space-y-1 md:items-center md:justify-between mb-2">

                        <div className='relative'>
                            <BiSearch className=" absolute top-[13px] left-2 text-gray-400" />
                            <input onChange={(e) => search(e.target.value)} placeholder='Search' className=' bg-gray-100 py-2 pl-7 rounded-sm' /></div>
                        <div className='flex justify-center items-center space-x-1'>
                            <p>Rows</p>
                            <input placeholder='Search' type='number'

                                value={itemPerPage}
                                onChange={(e) => e.target.value <= 4 ? {} : setItemPerPage(e.target.value)}

                                className=' bg-gray-100 py-2 pl-2 w-14 rounded-sm' />
                        </div>
                    </div>
                    <hr className=" border-grey" />

                    <table className="min-w-full">
                        <thead className=" text-left h-11">
                            <tr className="text-sm h-11 text-hint">
                                <th className=" py-1 px-2">S/N</th>
                                <th className=" py-1 px-2 ">Organization</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Date</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Status</th>
                                <th className=" py-1 px-2 hidden md:table-cell">NoticeId</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Delivered</th>
                                <th className=" py-1 px-2 hidden md:table-cell">Reminded</th>
                                <th className=" py-1 px-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedBusinesses.map((element, indexOnPage) => {
                                const globalIndex = indexOfFirstItem + indexOnPage + 1;
                                return (
                                    <tr
                                        key={globalIndex}
                                        className={`text-sm h-11 ${indexOnPage % 2 === 0 ? "bg-gray-100" : ""
                                            } text-hint`}
                                    >
                                        <td className=" py-1 pl-4 ">{globalIndex}</td>
                                        <td className=" py-1 px-2 ">{`${element.Business ? element.Business.organization : ""}`}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{formatIntDate(element.createdAt)}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell capitalize"><span className={element.status === 'approved' ? 'text-green-500' : element.status === 'rejected' ? 'text-red-500' : 'text-gray-500'}>{element.status}</span></td>
                                        <td className=" py-1 px-2 hidden md:table-cell">{element.noticeId}</td>
                                        <td className=" py-1 px-2 hidden md:table-cell ">{
                                            element.name ? <div className='flex justify-center text-green-600 text-2xl'> <BiCheckCircle className=' mr-16' /></div> : <div className='flex justify-center text-2xl'> <BiCheckCircle className=' mr-16' /></div>
                                        }</td>
                                        <td className=" py-1 px-2 hidden md:table-cell ">{
                                            element.remindername ? <div className='flex justify-center text-green-600 text-2xl'> <BiCheckCircle className='mr-16' /></div> : <div className='flex justify-center text-2xl'> <BiCheckCircle className=' mr-16' /></div>
                                        }</td>

                                        <td className=" py-1 px-2 flex space-x-1">
                                            <button onClick={() => editItem(globalIndex - 1)} className='bg-primary p-4 rounded-sm text-white flex justify-center items-center gap-x-2'>{authentication.role === 0 ? <MdPrint /> : <MdEditRoad />}{authentication.role === 0 ? <span className=' hidden md:block'>View</span> : <span className=' hidden md:block'>Update</span>}</button>
                                            {/* <button className=' bg-yellow-700 p-2 rounded-sm text-white'><MdDisabledVisible /></button> */}
                                            <button onClick={() => deleteDemand(element.id)} className='bg-red-500 p-4 rounded-sm text-white flex justify-center items-center gap-x-2'><MdDelete /> <span className=' hidden md:block'>Delete</span></button>
                                            <button onClick={() => toggleShowModal(indexOnPage)} className='bg-green-500 p-4 rounded-sm text-white'><MdVisibility /></button>

                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                    <table>
                        <tbody>
                            <tr className='flex items-center py-2 px-2 space-x-1'>
                                <td>
                                    <button
                                        onClick={() => paginate(currentPage - 1)}
                                        className={`flex justify-center items-center space-x-2 ${currentPage === 1 ? "disabled" : ""} border-gray border-2 px-2 py-2 rounded-md ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}

                                    >
                                        <MdArrowBack /><p>Previous</p>
                                    </button>

                                </td>
                                {Array.from({ length: Math.ceil(damands.length / itemPerPage) }, (_, index) => (
                                    <td key={index}>
                                        <p
                                            key={index}
                                            onClick={() => paginate(index + 1)}
                                            className={`px-4 py-2 rounded-md cursor-pointer ${currentPage === index + 1 ? 'bg-backgroud text-primary border-2 border-backgroud ' : ''}`}
                                        >
                                            {index + 1}
                                        </p>
                                    </td>
                                ))}

                                <td>
                                    <button
                                        onClick={() => paginate(currentPage + 1)}
                                        className={`flex ${currentPage === Math.ceil(damands.length / itemPerPage) === true ? "hidden" : ""} justify-center items-center space-x-2 border-gray border-2 px-2 py-2 rounded-md ${currentPage === Math.ceil(damands.length / itemPerPage) ? 'cursor-not-allowed opacity-50' : ''}`}>
                                        <p>Next</p><MdArrowForward />
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            {edit ? <UpdateDemand data={damands[selectedIndex]} editItem={editItem} /> : <div></div>}


        </div>
    )
}

export default Demand



