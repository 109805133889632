
import React, { useState, useEffect } from "react";
import { RiCloseFill } from 'react-icons/ri'
import { motion } from "framer-motion";
import { slideIn } from "../../motion";
import { BiEnvelope, BiPhone } from "react-icons/bi";
import { MdOutlinePerson } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import { register, adminupdate, updateprofile } from "../../constants/routes.path";
import { useDispatch } from "react-redux";
import authService from "../../app/service/auth.service";
import axios from "axios";
import { login } from "../../app/slice/auth.slice";
import { updateAccount, addAccount } from "../../app/slice/account.slice";



const ProfileModal = ({ onClose, isVisible, data, isProfile }) => {

    const dispatch = useDispatch();

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setLoading] = useState(false);

 
        useEffect(() => {
            function init() {
                setFirstname(!data? "": data.firstname);
                setLastname(!data? "":data.lastname);
                setEmail(!data? "": data.email);
                setPhone(!data? "": data.phone);
                setIsChecked(!data? "": data.role === 0 ? false : true)
            }

            init();

        }, [data]);


    const toggleLoading = () => {
        setLoading((prevLoading) => !prevLoading);
    };

    const handleSubmitEvent = async (e) => {
        e.preventDefault();

        if (!email || !firstname || !lastname || !phone) {
            toast.error('Please complete all fields.');
            return;
        }

        toggleLoading();
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await delay(1000);

        try {
            const userData = {
                email,
                firstname,
                lastname,
                phone,
                accountId: data ? data.id : "",
                role: isChecked ? 1 : 0,
                verified: data ? data.verified : true
            }

            if (isProfile) {
                const response = await axios.put(updateprofile, userData, { headers: authService.authHeader() });
                const message = response.data.message;
                const data = response.data.data;
                dispatch(login(data))
                localStorage.setItem("user", JSON.stringify(data));
                onClose()
                toast.success(message);
            } 
            
            if(!isProfile){
               if(data){
                console.log("user data", userData);
                const response = await axios.put(adminupdate, userData, { headers: authService.authHeader() });
                const message = response.data.message;
                const data = response.data.data;
                dispatch(updateAccount({ id: data.id, data }))
                localStorage.setItem("user", JSON.stringify(data));
                onClose()
                toast.success(message);
               }else{

                const newData = {
                    email,
                    firstname,
                    lastname,
                    password: "12345",
                    phone,
                    role:  isChecked ? 1 : 0,
                    verified: true
                  }
                const response = await axios.post(register, newData);
                const message = response.data.message;
                const data = response.data.data;
                dispatch(addAccount(data))
                localStorage.setItem("user", JSON.stringify(data));
                onClose()
                toast.success(message);
               }
            }



        } catch (error) {
            console.log(error)
            toast.error(error.response.statusText);
        } finally {
            toggleLoading();
        }
    };


    return (

        <div className={isVisible ? 'hidden' : 'fixed z-50 w-[100%] md:w-[94%] h-[85%] bg-background bg-opacity-80  flex justify-center items-center'}>
            <ToastContainer />
            <form method="post" onSubmit={handleSubmitEvent} className={'w-[90%] mr-10 md:mr-0 md:w-[40%]  rounded-md md:left-[25%]  bg-white absolute'}>
                <div className=' flex items-center justify-between px-5 py-3'>
                    <p className=' text-hint font-semibold'> Edit Profile</p>
                    <button onClick={onClose} type="button"><RiCloseFill className=' text-red-500' /></button>
                </div>
                <hr className=' my-2' />

                <div className='flex space-x-5 mx-5'>

                    <motion.div
                        initial={slideIn("up", null).initial}
                        whileInView={slideIn("up", 0 * 2).animate}
                        className=" w-1/2 mt-5"
                    >
                        <p className="text-hint">Firstname</p>
                        <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
                            <MdOutlinePerson className="text-2xl text-hint" />
                            <input
                                value={firstname}
                                onChange={(e) => {
                                    setFirstname(e.target.value);
                                }}
                                className="w-full bg-transparent  focus:outline-none rounded-md py-3 px-2 intrude font-light"
                                placeholder="Enter firstname"
                                type="firstname"
                                name="firstname"
                                autoComplete="given-name"
                            />
                        </div>
                    </motion.div>

                    <motion.div
                        initial={slideIn("up", null).initial}
                        whileInView={slideIn("up", 2 * 2).animate}
                        className=" w-1/2 mt-5"
                    >
                        <p className="text-hint">Lastname</p>
                        <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
                            <MdOutlinePerson className="text-2xl text-hint" />
                            <input
                                value={lastname}
                                onChange={(e) => {
                                    setLastname(e.target.value);
                                }}
                                className="w-full bg-transparent  focus:outline-none rounded-md px-2 py-3 font-light"
                                placeholder="Enter lastname"
                                type="lastname"
                                name="lastname"
                                autoComplete="family-name"
                            />
                        </div>
                    </motion.div>
                </div>


                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 3 * 2).animate}
                    className=" mx-5  mt-3"
                >
                    <p className="text-hint">Email</p>
                    <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
                        <BiEnvelope className="text-2xl text-hint" />
                        <input
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            className="w-full bg-transparent  focus:outline-none rounded-md px-2 py-3 font-light"
                            placeholder="Enter email"
                            type="email"
                            name="email"
                            autoComplete="email"
                        />
                    </div>
                </motion.div>

                <motion.div
                    initial={slideIn("up", null).initial}
                    whileInView={slideIn("up", 4 * 2).animate}
                    className="mx-5 mt-3"
                >
                    <p className="text-hint">Phone</p>
                    <div className="flex mt-2 px-3 rounded-sm  justify-center items-center bg-background">
                        <BiPhone className="text-2xl text-hint" />
                        <input
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                            }}
                            className="w-full bg-transparent  focus:outline-none rounded-md px-2 py-3 font-light"
                            placeholder="Enter phone"
                            type="phone"
                            name="phone"
                            autoComplete="phone"
                        />
                    </div>
                </motion.div>

                <div className='flex justify-between mx-5 mt-5 mb-3'>
                    <div className={isProfile ? "hidden" : 'flex items-center  space-x-2'}>
                        <input type='checkbox' onChange={(e) => { setIsChecked(e.target.checked) }} checked={isChecked} name='isAdmin' />
                        <p>Set as Admin</p>
                    </div>
                    <button type="submit" className="text-sm flex justify-center items-center gap-x-2 text-white bg-gradient px-5 py-2 font-light rounded-md">

                        {isLoading ? "Updating" : "Update"}
                    </button>
                </div>

            </form>

        </div>


    )
}

export default ProfileModal