import axios from 'axios'
import authService from './auth.service';
import { businesses, categorypricing } from '../../constants/routes.path';
import { loadBusiness, loadCategories } from '../slice/business.slice';

const fetchBusiness = async (dispatch) => {


  try {

    const response =  await axios.get(businesses, { headers: authService.authHeader()});
    const business = response.data;
    dispatch(loadBusiness(business.data));
    localStorage.setItem("businesses", JSON.stringify(business.data));

  } catch (error) {

    console.log("this error", error);

  } finally {
    // pass
  }

};

const categoryPricing = async (dispatch) => {
  try {
    const response =  await axios.get(categorypricing, { headers: authService.authHeader()});
    const business = response.data;
    dispatch(loadCategories(business.data));
    localStorage.setItem("pricing", JSON.stringify(business.data));

  } catch (error) {

    console.log("this error", error);

  } finally {
    // pass
  }

};

const data = { fetchBusiness, categoryPricing };

export default data