import React, { useState } from "react";
import { motion } from "framer-motion";
import { slideIn } from "../../../motion";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { paymentproof } from "../../../constants/routes.path";
import { ToastContainer, toast } from 'react-toastify';


const UploadSection = () => {
  const [isLoading, setLoading] = useState(false);
  const [noticeId, setNoticeId] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);


  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    toggleLoading();
    // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    // await delay(1000);

    try {
      const formData = new FormData();
      formData.append('noticeId', noticeId);
      formData.append('file', selectedFile);
      formData.append('amount', amount);
      await axios.post(paymentproof, formData);
      toast.success("Proof uploaded succesfuly");

  } catch (error) {
      toast.error(error.response.data.message);

  } finally {
      toggleLoading();
  }
  };

  const toggleLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const clearSelection=()=>{
    setSelectedFile(null)
    setPreview(null)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file);

      // Read the file and generate a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreview(null);
    }
  };

  let navigate = useNavigate();

  const backtoLogin = ()=>{
    navigate('/authentication')
  }

  return (
    <form method="post" onSubmit={handleSubmitEvent}>
      
      <ToastContainer />

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 1 * 2).animate}
        className=" mt-8"
      >
        <p className="text-hint">Notice Id</p>
        <div className="flex py-3 px-3 rounded-sm  justify-center items-center bg-background">
          <input
            value={noticeId}
            onChange={(e) => setNoticeId(e.target.value)}
            className="w-full bg-transparent  focus:outline-none rounded-md px-2  font-light"
            placeholder="Enter Demand Notice Id"
            type="text"
            name="noticeId"
            autoComplete="noticeId"
          />
        </div>
      </motion.div>

      <motion.div
        initial={slideIn("right", null).initial}
        whileInView={slideIn("right", 2 * 2).animate}
        className=" mt-3"
      >
        <p className="text-hint">Amount Paid</p>
        <div className="flex mt-2 py-3 px-3 rounded-sm  justify-center items-center bg-background">
          <input
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full bg-transparent  focus:outline-none rounded-md px-2  font-light"
            placeholder="Enter Demand Notice Id"
            type="number"
            name="noticeId"
            autoComplete="noticeId"
          />
        </div>
      </motion.div>

      <motion.div
       initial={slideIn("right", null).initial}
       whileInView={slideIn("right", 4 * 2).animate}

      className="my-4">
        <label className="block text-sm font-medium text-gray-700">
          Select your proof
        </label>

        <div className="mt-1 flex items-center bg-background py-3">

          <label className="relative cursor-pointer ml-3">
            <span className="text-xs w-[100%] py-2 px-8 bg-gradient text-white rounded-sm">Upload</span>
            <input type="file" className="sr-only"  onChange={handleFileChange} />
          </label>

          <span className="ml-2 text-sm text-gray-500">
          {selectedFile ? selectedFile.name : "No file chosen"}
          </span>

        </div>


        {preview && (
          <div className="mt-2">
            <p className="text-sm font-medium text-gray-700">File Preview: <button>{<AiFillCloseCircle onClick={clearSelection} className=" text-red-500"/>}</button></p>
            <img className="mt-1 w-40 h-20 object-cover rounded-md" src={preview} alt="File Preview" />
            
          </div>
        )}
      </motion.div>

      <motion.button
        initial={slideIn("down", null).initial}
        whileInView={slideIn("down", 0 * 2).animate}
        className="bg-gradient mt-2 w-[100%] py-3 rounded-sm text-white"
        type="submit"
      >
        {isLoading ? "Please wait..." : "Verify"}
      </motion.button>

      <div className=" w-[100%]">
            <p className=" text-center mt-5 text-hint">
              return back to{" "}
              <button onClick={backtoLogin} className="text-primary font-bold">
                Login
              </button>
            </p>
          </div>

    </form>
    
  );
};

export default UploadSection;
