import { createSlice } from '@reduxjs/toolkit';


const storedUser = JSON.parse(localStorage.getItem('user'));

const initialState = storedUser ? {
  isAuthenticated: true,
  user: storedUser,
  loading: false,
  error: "",
  response: ""
} : {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: "",
  response: ""
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },

    userUpdated(state, action) {
      const { id, name, email } = action.payload;
      const existingUser = state.find((user) => user.id === id);
      if (existingUser) {
        existingUser.name = name;
        existingUser.email = email;
      }
    },

    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
});

export const { login, userUpdated, logout } = authSlice.actions;
export default authSlice.reducer;
